/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-no-undef */

import Layout from '../../components/layout/layout';
import TopNavigation from '../../components/top-navigation/top-navigation';
import EditMerchantInput from './component/EditMerchantInput';
const EditMerchant = () => {
  return (
    <>
      <Layout>
        <TopNavigation middleTitle="e-Card Details" />
        <div className="flex justify-center mt-[60px] md:mt-10" />
        <EditMerchantInput />
      </Layout>
    </>
  );
};

export default EditMerchant;
