import React, { FC, ReactNode } from 'react';
import ScrollToTop from '../scroll-to-top/scroll-to-top';

interface Props {
  children: ReactNode;
  className?: string;
  containerClassName?: string;
}

const CustomModal: FC<Props> = ({
  children,
  className,
  containerClassName,
}) => {
  return (
    <ScrollToTop>
      <div
        className={`min-h-screen py-20 w-full modal-bg flex pt-[120px] lg:items-center justify-center ${containerClassName}`}
      >
        <div
          className={`p-5 md:p-[30px] w-[90%] max-w-[480px] h-fit bg-white 
          rounded-lg overflow- ${className} `}
        >
          {children}
        </div>
      </div>
    </ScrollToTop>
  );
};

export default CustomModal;
