import { createAsyncThunk } from '@reduxjs/toolkit';
import e_cardService from './e_cardService';
import { _UPLOAD_FILE } from '../customer/customerActions';

export const _CREATE_E_CARD = createAsyncThunk(
  'CREATE_E_CARD',
  async (
    {
      navigate,
      first_name,
      last_name,
      phone_number,
      gender,
      residential_address,
      product_type,
      amount,
      duration,
      frequency,
      cardName,
    }: any,
    { dispatch, rejectWithValue }
  ) => {
    const stringAmount = String(amount);

    const data =
      product_type === 'flexible'
        ? {
            first_name,
            last_name,
            phone_number,
            gender,
            residential_address,
            product_type,
            amount: stringAmount,
            frequency,
            ecard_name: cardName,
          }
        : {
            first_name,
            last_name,
            phone_number,
            gender,
            residential_address,
            product_type,
            amount: stringAmount,
            duration,
            frequency,
            ecard_name: cardName,
          };

    try {
      const res = await e_cardService.CREATE_ECARD({
        ...data,
      });

      dispatch(_GET_CONTRIBUTORS()).unwrap();
      navigate('/success', {
        state: {
          title: 'E-card issued successfully',
          redirectTo: '/dashboard',
          message:
            'You have successfully created a new contributor. You can mark savings and  manage this contributor from your dashboard',
        },
      });

      return res.data;
    } catch (err: any) {
      const error = err.response.data;
      return rejectWithValue(error);
    }
  }
);

export const _GET_CONTRIBUTORS = createAsyncThunk(
  'GET_CONTRIBUTORS',
  async (_: void, { rejectWithValue }) => {
    try {
      const res = await e_cardService.GET_CONTRIBUTORS();
      return res.data.contributors;
    } catch (err: any) {
      const error = err.response.data;
      return rejectWithValue(error);
    }
  }
);

export const _GET_E_CARDS = createAsyncThunk(
  'GET_E_CARDS',
  async (_, { rejectWithValue }: any) => {
    try {
      const res = await e_cardService.GET_E_CARDS();
      return res.data.ecard;
    } catch (err: any) {
      const error = err.response.data;
      return rejectWithValue(error);
    }
  }
);

export const _GET_CONTRIBUTOR_E_CARDS = createAsyncThunk(
  'GET_CONTRIBUTOR_E_CARDS',
  async (contributor_id: string, { rejectWithValue }) => {
    try {
      const res = await e_cardService.GET_CONTRIBUTOR_E_CARDS(contributor_id);
      return res.data.ecard;
    } catch (err: any) {
      const error = err.response.data;
      return rejectWithValue(error);
    }
  }
);

export const _UPDATE_E_CARD = createAsyncThunk(
  'UPDATE_E_CARD',
  async (
    {
      ecard_id,
      contributor_id,
      profile_pic,
      phone_number,
      gender,
      bvn,
      nin,
      product_type,
      minimum_amount,
      minimum_frequency,
      minimum_duration,
      residential_address,
      nok_first_name,
      nok_phone_number,
      nok_email_address,
      navigate,
    }: any,
    { rejectWithValue, dispatch }
  ) => {
    const profileUrl: string =
      typeof profile_pic !== 'string'
        ? await dispatch(_UPLOAD_FILE(profile_pic)).unwrap()
        : profile_pic;

    const body: any = {
      ecard_id,
      contributor_id,
      product_type,
      minimum_amount,
      minimum_frequency,
      phone_number,
      gender,
    };

    if (gender !== '') {
      body.gender = gender;
    }

    if (profileUrl !== '') {
      body.profile_pic = profileUrl;
    }

    if (bvn !== '') {
      body.bvn = bvn;
    }

    if (nin !== '') {
      body.nin = nin;
    }

    if (minimum_duration !== '') {
      body.minimum_duration = minimum_duration;
    }

    if (residential_address !== '') {
      body.residential_address = residential_address;
    }

    if (nok_first_name !== '') {
      body.nok_first_name = nok_first_name;
    }

    if (nok_phone_number !== '') {
      body.nok_phone_number = nok_phone_number;
    }

    if (nok_email_address !== '') {
      body.nok_email_address = nok_email_address;
    }

    try {
      const res = await e_cardService.UPDATE_ECARD(body);

      navigate('/success', {
        state: {
          title: 'Contributor updated successfully',
          redirectTo: '/e-card/logs',
          // message:
          //   'At just 30 years old, he is widely considered one of Europe’s most talented and accomplished young entrepreneurs and philosophical thinkers.',
        },
      });
      return res.data;
    } catch (err: any) {
      const error = err.response.data;
      return rejectWithValue(error);
    }
  }
);
