import React, { useEffect } from 'react';
import Layout from '../../components/layout/layout';
import TopNavigation from '../../components/top-navigation/top-navigation';
import { EditIcon, UserAvatar } from '../../assets/icons';
import ProfileItem from './components/profile-item/profile-item';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { _MERCHANT_PROFILE } from '../../redux/customer/customerActions';
import Button from '../../components/button/button';
import { getDateString } from '../../utils';

const BusinessProfile = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const { merchantProfile } = useSelector((state: RootState) => state.customer);

  useEffect(() => {
    dispatch(_MERCHANT_PROFILE());
  }, []);

  return (
    <Layout>
      <TopNavigation middleTitle="Business Profile" />
      <div className="mt-10 w-full flex flex-col pt-10 md:pt-0">
        <div className="!w-[80px] h-[80px] rounded-full self-center">
          <img
            src={UserAvatar}
            className="self-center w-20 h-20"
            alt="avatar"
          />
        </div>
        <div className="flex flex-col items-center justify-center gap-[6px] mt-5">
          <h5 className="text-base font-semibold flex items-center gap-1">
            {merchantProfile?.business_name || '--'}{' '}
            {/* <img src={GreenStar} alt="start" /> */}
          </h5>
          <p className="text-sm text-grey400 text-medium">
            ({merchantProfile?.customer_identifier || '--'})
          </p>
        </div>
      </div>

      <div className="mt-10 flex flex-col gap-10">
        <ProfileItem
          title={'ACCOUNT MANAGER'}
          value={merchantProfile?.customer_name || '--'}
        />

        <ProfileItem
          title={'CONTACT PHONE NUMBER'}
          value={merchantProfile?.phone_number || '--'}
        />

        <ProfileItem
          title={'CONTACT EMAIL ADDRESS'}
          value={merchantProfile?.email_address || '--'}
        />

        <ProfileItem
          title={'OFFICE LOCATION'}
          value={merchantProfile?.business_address || '--'}
        />

        <ProfileItem
          title={'NEXT OF KIN'}
          value={merchantProfile?.next_of_kin_name || '--'}
        />

        {/* <ProfileItem
          title={'BANK INFORMATION'}
          value={
            <span
              onClick={() => navigate('/add-bank')}
              className="flex items-center gap-2 font-medium text-blue cursor-pointer !w-fit"
            >
              <img src={Pen} alt="" /> Setup Bank Details
            </span>
          }
        /> */}

        {/* <ProfileItem
          title={'BANK INFORMATION'}
          value={
            <div className="flex items-center justify-between">
              <span className="flex items-center gap-2 !w-fit">
                <img src={FCMB} alt="" />
                12345678 - FCMB
              </span>
              <span
                onClick={() => navigate('/add-bank')}
                className="flex items-center gap-2 font-medium text-blue cursor-pointer !w-fit"
              >
                <img src={Pen} alt="" /> Edit
              </span>
            </div>
          }
        /> */}

        <h4 className="text-[12px] w-fit py-[.2rem] px-[.5rem] bg-grey100 rounded-lg text-grey400">
          Account created at: {getDateString(merchantProfile?.created_date)}
        </h4>

        <Button
          isValid={true}
          type={'button'}
          icon={EditIcon}
          onClick={() =>
            navigate('/error', {
              state: {
                title: 'Edit Business Details',
                redirectTo: 'mailto:support@payhaq.com',
                btnTitle: 'Contact Support',
                message:
                  'At just 30 years old, he is widely considered one of Europe’s most talented and accomplished young entrepreneurs and philosophical thinkers.',
              },
            })
          }
          title={'Edit Business Details'}
          className="!border border-blue !bg-transparent !text-blue"
        />
      </div>
    </Layout>
  );
};

export default BusinessProfile;
