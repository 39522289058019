import React, { FC, ReactNode } from 'react';
import ScrollToTop from '../scroll-to-top/scroll-to-top';

interface Props {
  children: ReactNode;
  width?: number;
}

const Layout: FC<Props> = ({ children, width }) => {
  return (
    <ScrollToTop>
      <div className="bg-grey100 min-h-screen w-full md:py-10 no-scrollbar">
        <div
          className="relative w-full md:max-w-[500px] min-h-screen md:min-h-[437px] no-scrollbar
          px-5 py-6 pb-10 md:py-10 mx-auto md:p-10 bg-white rounded-lg !overflow-hidden"
          style={{ maxWidth: width }}
        >
          {children}
        </div>
      </div>
    </ScrollToTop>
  );
};

export default Layout;
