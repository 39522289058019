import React, { FC } from 'react';

interface Props {
  currentStep?: number;
  totalStep?: number;
}

const SetupStep: FC<Props> = ({ currentStep, totalStep }) => {
  return (
    <div className="min-w-[86.2px] h-[30px] px-3 py-2 bg-gray-50 rounded-[32px] justify-center items-center gap-2.5">
      <p className="text-neutral-400 text-xs font-medium leading-[14px]">
        Step {currentStep} of {totalStep}
      </p>
    </div>
  );
};

export default SetupStep;
