import React from 'react';
import Button from '../button/button';
import { useNavigate } from 'react-router-dom';

const NoContributor = () => {
  const navigate = useNavigate();
  return (
    <div className="pt-10 pb-5 max-w-[337px] mx-auto flex flex-col items-center justify-center w-full">
      <h4 className="text-lg font-semibold">No contributor found</h4>
      <p className="pt-2.5 text-center text-grey400 text-xs font-normal leading-[20px] !max-w-[310px]">
        At just 30 years old, he is widely considered one of Europe’s most
        talented and accomplished young
      </p>
      <Button
        isValid={true}
        type={'button'}
        title={'Create New E-Card'}
        onClick={() => navigate('/create-e-card/contributor-information')}
        className="mt-10 !min-w-fit"
      />
    </div>
  );
};

export default NoContributor;
