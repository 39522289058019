/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { formatAmount } from '../../utils';
import CalendarWeeks from './components/calender-weeks/calender-weeks';

interface Props {
  contributor: any;
  selectedEcard: any;
  setFieldValue: (value: any, count: number) => void;
}

const DateRange: FC<Props> = ({
  contributor,
  selectedEcard,
  setFieldValue,
}) => {
  const [days, setDays] = useState<number>(0);
  const [endDate, setEndDate] = useState<any>();
  const [startDate, setStartDate] = useState<any>();
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [selectMode, setSelectMode] = useState<
    'daily' | 'weekly' | 'bi-weekly'
  >('daily');

  const markingDuration = new Date(selectedEcard?.end_date) || null;
  markingDuration.setDate(markingDuration.getDate() + 1);

  const createdDate = new Date(contributor?.created_date);
  const lastDate =
    selectedEcard?.end_date !== null
      ? new Date(markingDuration)
      : new Date(createdDate.getFullYear(), createdDate.getMonth(), 1);

  const formatted = (date: any) => moment(date).format('YYYY-MM-DD');

  const handleDateChange = (date: Date) => {
    if (
      selectedDates.some(
        (selectedDate) => selectedDate.getTime() === date.getTime()
      )
    ) {
      setSelectedDates(
        selectedDates.filter(
          (selectedDate) => selectedDate.getTime() !== date.getTime()
        )
      );
    } else {
      setSelectedDates([...selectedDates, date]);
    }
  };

  useEffect(() => {
    setSelectMode(selectedEcard.product.minimum_frequency);
    setSelectedDates([]);
  }, [selectedEcard]);

  useEffect(() => {
    if (selectedDates) {
      console.log('🚀 ~ useEffect ~ selectedDates:', selectedDates);
      const start = selectedDates[0];
      const end = selectedDates[selectedDates.length - 1]
        ? selectedDates[selectedDates.length - 1]
        : selectedDates[0];
      const datesBetween: any = [];
      const currentDate = new Date(start);
      if (end === undefined && currentDate) {
        datesBetween.push(new Date(currentDate));

        const formattedDates = datesBetween.map((date: any) => formatted(date));
        console.log('🚀 ~ useEffect ~ formattedDates:', formattedDates);
        const areValidDates = formattedDates.every(
          (date: string) => !isNaN(new Date(date).getTime())
        );
        console.log('🚀 ~ useEffect ~ areValidDates:', areValidDates);

        if (areValidDates) {
          const validDates = [formatted(start), formatted(end)];
          setDays(datesBetween.length);
          setFieldValue(validDates, datesBetween.length);
        } else {
          setFieldValue('', 0);
          setDays(0);
        }
      } else {
        while (currentDate <= end) {
          datesBetween.push(new Date(currentDate));
          currentDate.setDate(currentDate.getDate() + 1);
        }

        const formattedDates = datesBetween.map((date: any) => formatted(date));
        const areValidDates = formattedDates.every(
          (date: string) => !isNaN(new Date(date).getTime())
        );

        if (areValidDates) {
          const validDates = [formatted(start), formatted(end)];
          setDays(datesBetween.length);
          setFieldValue(validDates, datesBetween.length);
        } else {
          setFieldValue('', 0);
          setDays(0);
        }
      }

      setStartDate(start);
      setEndDate(end);
    }
  }, [selectedDates]);

  return (
    <div className="">
      {selectMode === 'daily' ? (
        <div className="pt-5 pb-1 px-5 border border-grey100 rounded-[10px]">
          <p className="!mb-[15px] text-xs font-medium leading-[14px]">
            Select Day(s)*
          </p>
          <DatePicker
            inline
            selected={null}
            onChange={handleDateChange}
            highlightDates={selectMode === 'daily' ? selectedDates : []}
            endDate={endDate}
            startDate={startDate}
            minDate={lastDate}
            calendarClassName="!w-full"
          />
        </div>
      ) : (
        <CalendarWeeks
          disabledLastDate={lastDate}
          setSelectedDates={setSelectedDates}
        />
      )}

      {/* <WeeksPicker year={2024} month={8} /> */}

      <div className="mt-5 w-full flex items-center gap-[10px] py-2.5 px-4 bg-grey100 rounded-full">
        <div className="!w-6 !h-6 !bg-white flex items-center justify-center rounded-full text-center text-sm font-medium leading-3">
          ₦
        </div>
        <p className="text-grey400 text-xs font-normal">
          You are saving{' '}
          <span className="text-black">
            {' '}
            {formatAmount(selectedEcard?.product?.minimum_amount)}
          </span>{' '}
          each for{' '}
          <span className="text-black">
            {days} {days === 1 ? 'day' : 'days'}
          </span>{' '}
          for{' '}
          <span className="text-black">
            {contributor
              ? `${contributor?.first_name} ${contributor?.last_name}`
              : '--'}
          </span>
        </p>
      </div>
    </div>
  );
};

export default DateRange;
