import React, { useEffect, useState } from 'react';
import Layout from '../../../components/layout/layout';
import TopNavigation from '../../../components/top-navigation/top-navigation';
import InputField from '../../../components/input-field/input-field';
import Button from '../../../components/button/button';

import * as Yup from 'yup';
import { Form, Formik } from 'formik';

import { useNavigate } from 'react-router-dom';
import SelectField from '../../../components/select-field/select-field';
import { Duration, Frequency, Gender, ProductType } from '../../../utils';
import SectionTitle from '../section-title/section-title';
import {
  _CREATE_E_CARD,
  _GET_CONTRIBUTORS,
} from '../../../redux/e-card/e_cardAction';
import { toastify } from '../../../components/toastify/toastify';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import RadioButton from '../../business-details/components/radio-button/radio-button';

const ContributorsInformation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [selectedProduct, setSelectedProduct] = useState<'fixed' | 'flexible'>(
    'flexible'
  );
  const [isNewContributor, setIsNewContributor] = useState<boolean>(false);
  const { loading, contributors } = useSelector(
    (state: RootState) => state.e_card
  );

  useEffect(() => {
    dispatch(_GET_CONTRIBUTORS());
  }, []);

  return (
    <Layout>
      <TopNavigation rightTitle="CREATE e-CARD" />
      <div className="pt-[70px] md:pt-10 flex flex-col gap-10 no-scrollbar">
        <SectionTitle
          className="!text-sm !font-semibold"
          title={'Contributor’s Information'}
        />

        <Formik
          validateOnMount
          initialValues={
            selectedProduct === 'fixed'
              ? {
                  first_name: '',
                  last_name: '',
                  phone_number: '',
                  residential_address: '',
                  product_type: '',
                  amount: '',
                  frequency: '',
                  duration: '',
                  gender: '',

                  cardName: '',
                }
              : {
                  first_name: '',
                  last_name: '',
                  phone_number: '',
                  residential_address: '',
                  product_type: '',
                  amount: '',
                  frequency: '',
                  gender: '',
                  cardName: '',
                }
          }
          validationSchema={Yup.object(
            selectedProduct === 'fixed'
              ? {
                  first_name: Yup.string().required('This is a required field'),
                  last_name: Yup.string().required('This is a required field'),
                  phone_number: Yup.string().required(
                    'This is a required field'
                  ),
                  product_type: Yup.string().required(
                    'This is a required field'
                  ),
                  gender: Yup.string(),
                  amount: Yup.string().required('This is a required field'),
                  frequency: Yup.string().required('This is a required field'),
                  duration: Yup.string().required('This is a required field'),
                  cardName: Yup.string().required('This is a required field'),
                  residential_address: Yup.string(),
                }
              : {
                  first_name: Yup.string().required('This is a required field'),
                  last_name: Yup.string().required('This is a required field'),
                  phone_number: Yup.string().required(
                    'This is a required field'
                  ),
                  gender: Yup.string(),
                  product_type: Yup.string().required(
                    'This is a required field'
                  ),
                  amount: Yup.string().required('This is a required field'),
                  frequency: Yup.string().required('This is a required field'),
                  cardName: Yup.string().required('This is a required field'),
                  residential_address: Yup.string(),
                }
          )}
          onSubmit={async (values, { setErrors }) => {
            const errors = {};
            if (
              Object.entries(errors).length === 0 &&
              errors.constructor === Object
            ) {
              try {
                const res: any = await dispatch(
                  _CREATE_E_CARD({ ...values, navigate })
                ).unwrap();
              } catch (err: any) {
                toastify('error', err.message);
              }
              // navigate('/create-e-card/verification', { state: { ...values } });
            } else {
              setErrors(errors);
            }
          }}
        >
          {({ isValid, values, handleSubmit, setFieldValue }) => (
            <Form
              onSubmit={handleSubmit}
              className="flex flex-col gap-[30px] pb-20"
            >
              <div className="w-full flex flex-row gap-10 md:gap-5">
                <RadioButton
                  title={'New Contributor'}
                  isActive={isNewContributor}
                  onClick={() => {
                    setIsNewContributor(true);
                    setFieldValue('contributor_id', '');
                    setFieldValue('first_name', '');
                    setFieldValue('last_name', '');
                    setFieldValue('phone_number', '');
                    setFieldValue('residential_address', '');
                    setFieldValue('gender', '');
                  }}
                />
                <RadioButton
                  title={'Existing Contributor'}
                  isActive={!isNewContributor}
                  onClick={() => setIsNewContributor(false)}
                />
              </div>

              {!isNewContributor && (
                <SelectField
                  isContributor
                  data={contributors}
                  label={'Select Contributor*'}
                  placeholder={'eg Doe'}
                  setValue={(item: any) => {
                    setFieldValue('contributor_id', item.contributor_id);
                    setFieldValue('first_name', item.first_name);
                    setFieldValue('last_name', item.last_name);
                    setFieldValue('phone_number', item.phone_number);
                    setFieldValue(
                      'residential_address',
                      item.residential_address
                    );
                    setFieldValue(
                      'gender',
                      item.gender === 1 ? 'male' : 'female'
                    );
                  }}
                  setFieldValue={(value: string) =>
                    setFieldValue('contributor_name', value)
                  }
                />
              )}

              {isNewContributor && (
                <>
                  <div className="w-full flex flex-col  md:flex-row gap-[30px] md:gap-5">
                    <InputField
                      name="first_name"
                      label="First name*"
                      placeholder="eg John"
                      className="md:w-[50%]"
                    />
                    <InputField
                      name="last_name"
                      label="Last name*"
                      placeholder="eg John"
                      className="md:w-[50%]"
                    />
                  </div>

                  <div className="w-full flex flex-col  md:flex-row gap-[30px] md:gap-5">
                    <InputField
                      type="tel"
                      name="phone_number"
                      label="Phone number*"
                      placeholder="80 **** *****"
                      className="md:w-[50%]"
                    />

                    <SelectField
                      data={Gender}
                      label={'Gender'}
                      placeholder={'Select gender'}
                      setFieldValue={(value: string) =>
                        setFieldValue('gender', value)
                      }
                      className="md:w-[50%]"
                    />
                  </div>

                  <InputField
                    type="text"
                    name="residential_address"
                    label="Residential Address"
                    placeholder="House address, Area, LG, State....."
                  />
                </>
              )}

              <SectionTitle
                className="!text-sm !font-semibold my-2.5"
                title={'e-Card Details'}
              />

              <InputField
                name="cardName"
                label="Card Name*"
                placeholder="Name of eCard"
              />

              <SelectField
                type="product_type"
                data={ProductType}
                label={'Product Type*'}
                placeholder={'Select type'}
                setFieldValue={(value: 'fixed' | 'flexible' | any) => {
                  setFieldValue('product_type', value);
                  setSelectedProduct(value);
                }}
              />

              <InputField
                type="number"
                inputMode="decimal"
                name="amount"
                label="Amount*"
                placeholder="0.00"
              />

              <div className="w-full flex flex-col  md:flex-row gap-[30px] md:gap-5">
                <SelectField
                  data={Frequency}
                  label={'Frequency*'}
                  placeholder={'Select frequency'}
                  setFieldValue={(value: string) =>
                    setFieldValue('frequency', value)
                  }
                  className={`w-full ${
                    values.product_type.toLocaleLowerCase() === 'fixed' &&
                    'md:!w-[50%]'
                  }`}
                />
                {values.product_type.toLocaleLowerCase() === 'fixed' && (
                  <SelectField
                    data={Duration}
                    label={'Duration*'}
                    placeholder={'Select duration'}
                    setFieldValue={(value: string) =>
                      setFieldValue('duration', value)
                    }
                    className="md:w-[50%]"
                  />
                )}
              </div>

              <div className="flex flex-col gap-[25px]">
                <Button
                  isValid={isValid}
                  type={'submit'}
                  isLoading={loading}
                  title={
                    isNewContributor ? 'Create contributor' : 'Create eCard'
                  }
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};

export default ContributorsInformation;
