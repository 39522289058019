import React from 'react';
import Layout from '../../components/layout/layout';
import TopNavigation from '../../components/top-navigation/top-navigation';

const Referrals = () => {
  return (
    <Layout>
      {' '}
      <TopNavigation middleTitle="Referrals" />
    </Layout>
  );
};

export default Referrals;
