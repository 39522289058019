import React, { FC } from 'react';
import ActionButton from '../action-button/action-button';
import {
  ECard,
  EarningsIcon,
  MarkSavings,
  Settlement,
} from '../../../../assets/icons';
import type { AccountTypeProps } from '../balance/balance';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';

const DashboardActions: FC<AccountTypeProps> = ({ isMerchant }) => {
  const { merchantProfile } = useSelector((state: RootState) => state.customer);

  const navigate = useNavigate();
  const hasBusinessProfile =
    merchantProfile.business_name !== null &&
    merchantProfile.business_address !== null;

  return (
    <div className="mt-[30px] mb-10">
      {isMerchant ? (
        <div className="flex items-center justify-between mt-[30px] gap-3">
          <ActionButton
            title={'Create e-Card'}
            icon={ECard}
            isPurple
            onClick={
              hasBusinessProfile
                ? () => navigate('/create-e-card/contributor-information')
                : () => navigate('/business-verification')
            }
          />
          <ActionButton
            title={'Mark Savings'}
            icon={MarkSavings}
            onClick={
              hasBusinessProfile
                ? () =>
                    navigate('/mark-savings', {
                      state: { isMerchant: isMerchant },
                    })
                : () => navigate('/business-verification')
            }
          />
          <ActionButton
            title={'Settlement'}
            icon={Settlement}
            onClick={
              hasBusinessProfile
                ? () => navigate('/settlement')
                : () => navigate('/business-verification')
            }
          />
          <ActionButton
            title={'Earnings'}
            icon={EarningsIcon}
            onClick={() => navigate('/dashboard')}
          />
        </div>
      ) : (
        <div className="flex items-center justify-center w-full gap-[35px]">
          <ActionButton
            title={'Mark Savings'}
            // icon={MarkSavings}
            icon={ECard}
            onClick={() =>
              navigate('/mark-savings', { state: { isMerchant: isMerchant } })
            }
          />
          <ActionButton
            isPurple
            title={'Withdraw'}
            // icon={Settlement}
            icon={ECard}
            onClick={() => navigate('/settlement')}
          />
        </div>
      )}
    </div>
  );
};

export default DashboardActions;
