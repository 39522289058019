import React, { FC } from 'react';

interface Props {
  icon: string;
  title: string;
  desc: string;
  isActive: boolean;
  onClick: () => void;
}

const AccountType: FC<Props> = ({ icon, title, desc, isActive, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="w-full md:w-[220px] h-fit md:h-[185px] 
        border border-grey200 rounded-lg px-[15px] py-5 cursor-pointer"
    >
      <div className="flex justify-between items-start">
        <div
          className="w-10 h-10 rounded-full bg-grey100 
            flex items-center justify-center"
        >
          <img src={icon} alt="account type" />
        </div>

        <div className="w-[17px] h-[17px] rounded p-[3px] bg-grey100">
          <div
            className={`rounded w-[11px] h-[11px] ${
              isActive ? 'bg-green' : 'bg-grey100'
            }`}
          ></div>
        </div>
      </div>

      <div className="mt-5">
        <h4 className="text-black text-xs font-semibold">{title}</h4>
        <p className="text-grey400 text-[10px] font-normal mt-[6px]">{desc}</p>
      </div>
    </div>
  );
};

export default AccountType;
