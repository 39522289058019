import React, { useState } from 'react';
import Layout from '../../components/layout/layout';
import OnboardTitle from '../../components/onboard-title/onboard-title';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import InputField from '../../components/input-field/input-field';
import Button from '../../components/button/button';
import RadioButton from './components/radio-button/radio-button';
import { useNavigate } from 'react-router-dom';

const BusinessDetails = () => {
  const navigate = useNavigate();
  const [isBusinessRegistered, setIsBusinessRegistered] =
    useState<boolean>(true);

  return (
    <Layout>
      <div className="mt-[60px] md:mt-0" />

      <OnboardTitle
        hasNoLogo
        hasBackButton
        title={'Business Verification'}
        subText={'Complete setting up your account.'}
      />

      <Formik
        validateOnMount
        enableReinitialize
        initialValues={
          isBusinessRegistered
            ? {
                business_name: '',
                CAC_number: '',
                business_address: '',
              }
            : {
                business_name: '',
                business_address: '',
              }
        }
        validationSchema={
          isBusinessRegistered
            ? Yup.object({
                business_name: Yup.string().required(
                  'This is a required field'
                ),
                CAC_number: Yup.string().required('This is a required field'),
                business_address: Yup.string().required(
                  'This is a required field'
                ),
              })
            : Yup.object({
                business_name: Yup.string().required(
                  'This is a required field'
                ),
                business_address: Yup.string().required(
                  'This is a required field'
                ),
              })
        }
        onSubmit={(values, { setErrors }) => {
          const errors = {};
          if (
            Object.entries(errors).length === 0 &&
            errors.constructor === Object
          ) {
            navigate('/business-identity-verification', {
              state: { ...values },
            });
          } else {
            setErrors(errors);
          }
        }}
      >
        {({ isValid, handleSubmit }) => (
          <Form
            onSubmit={handleSubmit}
            className="mt-10 flex flex-col gap-[30px]"
          >
            <InputField
              name="business_name"
              label="Business name*"
              placeholder="eg John"
            />

            <div className="flex flex-col gap-[15px]">
              <label htmlFor="" className="text-xs font-medium leading-[14px]">
                Is your business registered with CAC?*
              </label>
              <div className="flex items-center gap-5">
                <RadioButton
                  title={'Yes'}
                  isActive={isBusinessRegistered}
                  onClick={() => setIsBusinessRegistered(true)}
                />
                <RadioButton
                  title={'No'}
                  isActive={!isBusinessRegistered}
                  onClick={() => setIsBusinessRegistered(false)}
                />
              </div>
            </div>

            {isBusinessRegistered && (
              <InputField
                name="CAC_number"
                label="CAC Number*"
                placeholder="eg John"
              />
            )}
            <InputField
              name="business_address"
              label="Business Address*"
              placeholder="eg John"
            />

            <div className="flex flex-col gap-[20px] mt-[10px]">
              {/* <Terms
                isActive={values.terms}
                onClick={() => setFieldValue('terms', !values.terms)}
              /> */}
              <Button isValid={isValid} type={'submit'} title={'Next'} />
            </div>
          </Form>
        )}
      </Formik>
    </Layout>
  );
};

export default BusinessDetails;
