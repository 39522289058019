import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout/layout';
import { Calendar, Menu, UserAvatar } from '../../assets/icons';
import Balance from './components/balance/balance';
import DashboardActions from './components/dashboard-actions/dashboard-actions';
import { account_type, thisDay } from '../../utils';
import LatestTransactions from './components/latest-transactions/latest-transactions';
import Sidebar from './components/sidebar/sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import AddToHomeScreen from '../../components/add-to-home-screen/AddToHomeScreen';
import {
  _GET_TRANSACTIONS,
  _MERCHANT_PROFILE,
} from '../../redux/customer/customerActions';
import { _GET_CONTRIBUTORS } from '../../redux/e-card/e_cardAction';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  const accountType = 'merchant';
  const isMerchant = account_type(accountType);
  const dispatch = useDispatch<AppDispatch>();
  const { merchantProfile } = useSelector((state: RootState) => state.customer);

  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  useEffect(() => {
    dispatch(_GET_CONTRIBUTORS());
    dispatch(_MERCHANT_PROFILE());
    dispatch(_GET_TRANSACTIONS());
  }, []);

  return (
    <Layout>
      <div className="!relative !overflow-hidden min-h-screen md:min-h-[700px]">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <Link to={'/business-profile'}>
              <img src={UserAvatar} alt="avatar" className="w-10 h-10" />
            </Link>
            <h4 className="text-sm font-semibold">
              {merchantProfile?.customer_name}
            </h4>
          </div>
          <div
            onClick={toggleSidebar}
            className="flex items-center gap-[15px] cursor-pointer"
          >
            {isMerchant ? (
              <></>
            ) : (
              <h4 className="text-sm font-semibold">Hello Ganiu 👋</h4>
            )}
            <img src={Menu} alt="menu icon" className="w-6 h-6" />
          </div>
        </div>

        <div>
          <div className="relative mt-10 w-full h-[140px] bg-black rounded-[10px] px-[25px] py-6">
            <span className="flex items-center gap-2.5">
              <img src={Calendar} alt="" className="white-icon" />
              <p className="text-grey400 text-xs font-normal font-['Montserrat'] leading-[14px]">
                {thisDay}
              </p>
            </span>

            <Balance isMerchant={isMerchant} />
          </div>
          <div className="bg-lightBlue h-[140px] rounded-[10px] mt-[-130px] mx-[10px]" />
        </div>

        <DashboardActions isMerchant={isMerchant} />

        <LatestTransactions />
        <Sidebar isOpen={isSidebarOpen} onClick={toggleSidebar} />
      </div>

      <AddToHomeScreen />
    </Layout>
  );
};

export default Dashboard;
