import React, { FC } from 'react';

interface Props {
  status: 'failed' | 'pending' | 'successful';
  className?: string;
}

const TransactionStatus: FC<Props> = ({ status, className }) => {
  return (
    <div
      className={`w-fit h-[35px] px-5 py-2.5 rounded-[100px] justify-center items-center ${
        status === 'successful'
          ? 'bg-greenBg'
          : status === 'failed'
            ? 'bg-lightRed'
            : 'bg-lightBlue'
      } ${className}`}
    >
      <div
        className={`text-xs font-medium capitalize ${
          status === 'successful'
            ? 'text-green'
            : status === 'failed'
              ? 'text-red'
              : 'text-blue'
        }`}
      >
        {status}
      </div>
    </div>
  );
};

export default TransactionStatus;
