import React from 'react';
import Button from '../../components/button/button';
import Layout from '../../components/layout/layout';
import TopNavigation from '../../components/top-navigation/top-navigation';
import SettingsContainer from './settings-container/settings-container';
import ResetLink from './reset-link/reset-link';

const AccountSettings = () => {
  return (
    <Layout>
      <TopNavigation middleTitle="Security" />
      <div className="flex flex-col gap-10 mt-[60px] md:mt-10">
        <SettingsContainer>
          <div>
            <h1 className="text-sm text-black font-semibold leading-[17px]">
              {'PIN & Password'}
            </h1>
            <h5 className="text-xs mt-[4px] leading-[16px] text-grey400 !max-w-fit">
              Proceed to change transaction pin and password.
            </h5>
          </div>

          <ResetLink url={'/reset-pin'} title={'pin'} value={'••••'} />
          <ResetLink
            url={'/change-password'}
            title={'password'}
            value={'••••••••••••••'}
          />
        </SettingsContainer>

        <SettingsContainer>
          <div>
            <h1 className="text-sm text-black font-semibold leading-[17px]">
              Setup 2FA
            </h1>
            <h5 className="text-xs mt-[4px] leading-[16px] text-grey400 !max-w-fit">
              Take extra security measures by setting up your 2FA.
            </h5>
          </div>

          <Button
            isValid={true}
            type={'button'}
            title={'Setup 2FA'}
            className="!bg-transparent border border-blue !text-blue !w-[123px] !h-[38px] !text-[14px]"
          />
        </SettingsContainer>

        {/* <SettingsContainer>
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-sm text-black font-semibold leading-[17px]">
                Account Activity
              </h1>
              <h5 className="text-xs mt-[4px] leading-[16px] text-grey400 !max-w-fit">
                Amet minim mollit non deserunt{' '}
              </h5>
            </div>
            <Button
              isValid={true}
              type={'button'}
              title={'See all'}
              className="!bg-transparent border border-blue !text-blue !w-[61px] !h-[26px] !text-xs !px-0"
            />
          </div>
        </SettingsContainer> */}
      </div>
    </Layout>
  );
};

export default AccountSettings;
