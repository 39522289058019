import axios from 'axios';

const setAuthorizationHeader = async () => {
  const token = localStorage.getItem('token');

  if (token) {
    return `Bearer ${token}`;
  }

  return 'Bearer ';
};

// axios instance
const api = axios.create({
  baseURL: 'https://api.trydashi.com/api/v1',
  headers: {
    'Content-type': 'application/json',
  },
});

api.interceptors.request.use(async (config) => {
  config.headers.authorization = await setAuthorizationHeader();
  return config;
});

export default api;
