/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import {
  format,
  addDays,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  getISOWeek,
} from 'date-fns';
import { PreFill, SelectedCheck, Unchecked } from '../../../../assets/icons'; // Import your icons
import SelectField from '../../../select-field/select-field';
import { Months } from '../../../../utils';

const CalendarWeeks = ({
  disabledLastDate,
  setSelectedDates,
}: {
  disabledLastDate: Date;
  setSelectedDates: any;
}) => {
  const year = new Date().getFullYear();
  const currentM = new Date().getMonth();
  const formattedMonths = Months.slice(currentM - 1);
  const [weeks, setWeeks] = useState([]);
  const [month, setMonth] = useState<string>((currentM + 1).toString());
  const [selectedWeeks, setSelectedWeeks] = useState<number[]>([]);

  useEffect(() => {
    const getMonthWeeks: any = (month: number) => {
      const startDate = startOfMonth(new Date(year, month - 1));
      const endDate = endOfMonth(new Date(year, month - 1));
      let currentWeekStart = startOfWeek(startDate);
      let currentWeekEnd = endOfWeek(startDate);

      const weeksInMonth = [];

      while (currentWeekStart <= endDate) {
        const firstDate = currentWeekStart;
        const lastDateOfWeek =
          currentWeekEnd > endDate ? endDate : currentWeekEnd;
        const weekNumber = getISOWeek(firstDate);

        weeksInMonth.push({
          month,
          week: weekNumber,
          firstDate: format(firstDate, 'yyyy-MM-dd'),
          lastDate: format(lastDateOfWeek, 'yyyy-MM-dd'),
        });

        currentWeekStart = addDays(currentWeekEnd, 1);
        currentWeekEnd = endOfWeek(currentWeekStart);
      }

      return weeksInMonth;
    };

    setWeeks(getMonthWeeks(month));
  }, [year, month]);

  const formatDateRange = (startDateStr: Date, endDateStr: Date) => {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    const formattedStartDate = format(startDate, 'MMMM do');
    const formattedEndDate = format(endDate, 'MMMM do');

    return `${formattedStartDate} - ${formattedEndDate}`;
  };

  const handleWeekSelect = (weekNumber: number) => {
    setSelectedWeeks((prevSelectedWeeks) => {
      const updatedSelectedWeeks = prevSelectedWeeks.includes(weekNumber)
        ? prevSelectedWeeks.filter((week) => week !== weekNumber)
        : [...prevSelectedWeeks, weekNumber];

      updateSelectedDates(updatedSelectedWeeks);

      return updatedSelectedWeeks;
    });
  };

  const updateSelectedDates = (selectedWeeks: number[]) => {
    if (selectedWeeks.length === 0) {
      setSelectedDates(null);
      return;
    }

    const selectedWeeksData: any = weeks.filter((week: any) =>
      selectedWeeks.includes(week.week)
    );

    const firstDate = new Date(selectedWeeksData[0].firstDate);
    const lastDate = new Date(
      selectedWeeksData[selectedWeeksData.length - 1].lastDate
    );

    setSelectedDates([firstDate, lastDate]);
  };

  return (
    <div className="pt-4 pb-2 px-4 border border-grey100 rounded-[10px]">
      <div className="flex items-center justify-between pb-2">
        <p className="text-xs font-medium leading-[14px]">Select Week(s)*</p>
        {/* <h2 className="text-sm font-medium mb-4">
          {format(new Date(year, month - 1), 'MMMM')}
        </h2> */}
        <SelectField
          data={formattedMonths}
          value={Months[currentM].name}
          placeholder={''}
          className="!w-[110px] !px-0 !h-fit"
          modalClassName="!top-[40px]"
          inputClassName="!border-none !bg-white !justify-end !px-0 !font-medium !h-[14px]"
          setFieldValue={(item) => setMonth(item)}
        />
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full">
          <thead>
            <tr>
              <th className="px-2 md:px-3 py-3 border-b bg-grey100 text-left text-xs md:text-sm font-medium text-black tracking-wider">
                Week
              </th>
              <th className="px-2 md:px-3 py-3 border-b bg-grey100 text-left text-xs md:text-sm font-medium text-black tracking-wider">
                Dates
              </th>
              <th className="px-2 md:px-3 py-3 border-b bg-grey100 text-left text-sm font-medium text-black tracking-wider"></th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {weeks.map((week: any, index) => {
              const isDisabled = new Date(week.lastDate) <= disabledLastDate;

              return (
                <tr key={index} className="border-t border-gray100">
                  <td className="px-2 md:px-3 py-4 whitespace-nowrap text-xs md:text-sm text-grey400 font-medium">
                    Wk {week.week}
                  </td>
                  <td className="px-2 md:px-3 py-4 whitespace-nowrap text-xs md:text-sm text-grey400 font-medium">
                    {formatDateRange(week.firstDate, week.lastDate)}
                  </td>
                  <td
                    className="px-2 md:px-3 py-4 whitespace-nowrap text-sm text-grey400 font-medium cursor-pointer"
                    onClick={() => !isDisabled && handleWeekSelect(week.week)}
                  >
                    <img
                      src={
                        isDisabled
                          ? PreFill
                          : selectedWeeks.includes(week.week)
                            ? SelectedCheck
                            : Unchecked
                      }
                      alt="selection"
                      className="w-[18px] h-[18px]"
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CalendarWeeks;
