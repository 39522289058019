import React, { FC } from 'react';
import OTPInput from 'react-otp-input';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Button from '../../../../components/button/button';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { toastify } from '../../../../components/toastify/toastify';

interface Props {
  type?: 'reset';
  errorMessage?: string | null;
  handleSubmit: any;
  isMerchant: boolean;
}

const PasscodeForm: FC<Props> = ({ type, handleSubmit, isMerchant }) => {
  const navigate = useNavigate();
  const { loading } = useSelector((state: RootState) => state.customer);

  return (
    <Formik
      validateOnMount
      initialValues={
        type === 'reset'
          ? {
              current_pin: '',
              pin: '',
              confirmPin: '',
            }
          : {
              pin: '',
              confirmPin: '',
            }
      }
      validationSchema={Yup.object(
        type === 'reset'
          ? {
              current_pin: Yup.string()
                .min(6, 'Please enter a valid otp')
                .max(6, 'Please enter a valid otp')
                .required('This is a required field'),
              pin: Yup.string()
                .min(6, 'Please enter a valid otp')
                .max(6, 'Please enter a valid otp')
                .required('This is a required field'),
              confirmPin: Yup.string()
                .min(6, 'Please enter a valid otp')
                .max(6, 'Please enter a valid otp')
                .required('This is a required field')
                .test('passwords-match', 'Code must match', function (value) {
                  return this.parent.pin === value;
                }),
            }
          : {
              pin: Yup.string()
                .min(6, 'Please enter a valid otp')
                .max(6, 'Please enter a valid otp')
                .required('This is a required field'),
              confirmPin: Yup.string()
                .min(6, 'Please enter a valid otp')
                .max(6, 'Please enter a valid otp')
                .required('This is a required field')
                .test('passwords-match', 'Code must match', function (value) {
                  return this.parent.pin === value;
                }),
            }
      )}
      onSubmit={async (values, { setErrors }) => {
        const errors = {};
        if (
          Object.entries(errors).length === 0 &&
          errors.constructor === Object
        ) {
          const res = await handleSubmit(values);

          toastify('success', res.message);
        } else {
          setErrors(errors);
        }
      }}
    >
      {({ isValid, values, touched, errors, handleSubmit, setFieldValue }) => (
        <Form
          onSubmit={handleSubmit}
          className={`relative mt-10 flex flex-col gap-10 ${
            isMerchant
              ? '!max-h-fit'
              : 'min-h-[60vh] md:min-h-[50vh] !max-h-screen'
          }`}
        >
          {type === 'reset' && (
            <div className="flex flex-col gap-5">
              <p className="text-left text-neutral-400 text-xs font-medium font-['Montserrat']">
                Current Pin
              </p>
              <OTPInput
                value={values.current_pin}
                onChange={(e) => {
                  setFieldValue('current_pin', e);
                }}
                onPaste={(event) => {
                  const data = event.clipboardData.getData('text');
                  setFieldValue('current_pin', data);
                }}
                inputStyle={{
                  width: 40,
                  height: 40,
                  borderRadius: 8,
                  fontSize: 17,
                  fontFamily: 'Montserrat',
                  background: 'white',
                  border: '1px solid  #F0F2F5',
                  outline: '#4DA34E',
                }}
                containerStyle={{
                  padding: '20px 16px',
                  width: '100%',
                  borderRadius: 8,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  background: '#F8F9FB',
                }}
                numInputs={6}
                renderInput={(props, index) => (
                  <input
                    {...props}
                    type="tel"
                    inputMode="numeric"
                    name={`otp-input-${index}`}
                    className={`focus:!border-green focus:!bg-lightGreen md:!w-[50px] md:!h-[50px] ${
                      touched.current_pin &&
                      errors.current_pin &&
                      '!border !border-red !bg-lightRed'
                    }`}
                  />
                )}
              />
            </div>
          )}

          <div className="flex flex-col gap-5">
            <p className="text-left text-neutral-400 text-xs font-medium font-['Montserrat']">
              {type === 'reset' ? 'New Pin' : 'Input PIN'}
            </p>
            <OTPInput
              value={values.pin}
              onChange={(e) => {
                setFieldValue('pin', e);
              }}
              onPaste={(event) => {
                const data = event.clipboardData.getData('text');
                setFieldValue('pin', data);
              }}
              inputStyle={{
                width: 40,
                height: 40,
                borderRadius: 8,
                fontSize: 17,
                fontFamily: 'Montserrat',
                background: 'white',
                border: '1px solid  #F0F2F5',
                outline: '#4DA34E',
              }}
              containerStyle={{
                padding: '20px 16px',
                width: '100%',
                borderRadius: 8,
                alignItems: 'center',
                justifyContent: 'space-between',
                background: '#F8F9FB',
              }}
              numInputs={6}
              renderInput={(props, index) => (
                <input
                  {...props}
                  type="tel"
                  inputMode="numeric"
                  name={`otp-input-${index}`}
                  className={`focus:!border-green focus:!bg-lightGreen md:!w-[50px] md:!h-[50px] ${
                    touched.pin &&
                    errors.pin &&
                    '!border !border-red !bg-lightRed'
                  }`}
                />
              )}
            />
          </div>

          <div className="flex flex-col gap-5">
            <p className="text-left text-neutral-400 text-xs font-medium font-['Montserrat']">
              {type === 'reset' ? 'Confirm New Pin' : 'Confirm PIN'}
            </p>
            <OTPInput
              value={values.confirmPin}
              onChange={(e) => {
                setFieldValue('confirmPin', e);
              }}
              onPaste={(event) => {
                const data = event.clipboardData.getData('text');
                setFieldValue('confirmPin', data);
              }}
              inputStyle={{
                width: 40,
                height: 40,
                borderRadius: 8,
                fontSize: 17,
                fontFamily: 'Montserrat',
                background: 'white',
                border: '1px solid  #F0F2F5',
                outline: '#4DA34E',
              }}
              containerStyle={{
                padding: '20px 16px',
                width: '100%',
                borderRadius: 8,
                alignItems: 'center',
                justifyContent: 'space-between',
                background: '#F8F9FB',
              }}
              numInputs={6}
              renderInput={(props, index) => (
                <input
                  {...props}
                  type="tel"
                  inputMode="numeric"
                  name={`otp-input-${index}`}
                  className={`focus:!border-green focus:!bg-lightGreen md:!w-[50px] md:!h-[50px] ${
                    touched.confirmPin &&
                    errors.confirmPin &&
                    '!border !border-red !bg-lightRed'
                  }`}
                />
              )}
            />

            <ErrorMessage name={'confirmPin'} className="text-red" />
          </div>

          <Button
            isValid={isValid}
            isLoading={loading}
            type={'submit'}
            title={'Continue'}
          />
        </Form>
      )}
    </Formik>
  );
};

export default PasscodeForm;
