import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/layout/layout';
import AccountType from './components/account-type/account-type';
import Button from '../../components/button/button';
import UrlText from '../../components/url-text/url-text';

import { IndividualIcon, MerchantIcon } from '../../assets/icons';
import OnboardTitle from '../../components/onboard-title/onboard-title';

const Welcome = () => {
  const navigate = useNavigate();
  const [accountType, setAccountType] = useState<null | string>(null);
  return (
    <Layout width={540}>
      <OnboardTitle
        title="Welcome, let’s get you started"
        subText="Between profitability and rewardability!"
      />

      <div className="mt-[50px] flex flex-col md:flex-row items-center gap-5">
        <AccountType
          icon={IndividualIcon}
          title={'Personal'}
          desc="YAs an Individual User, you can easily
make savings and investments
directly through our platform. Start
your journey towards financial growth"
          isActive={accountType === 'individual'}
          onClick={() => setAccountType('individual')}
        />
        <AccountType
          icon={MerchantIcon}
          title={'Merchants'}
          desc="As a merchant, you own a small
business, registered with the CAC or
not. (i.e Mobile Money Agents,
Online traders, artisans etc)"
          isActive={accountType === 'merchant'}
          onClick={() => setAccountType('merchant')}
        />
      </div>

      <div className="mt-[50px] flex flex-col gap-[25px]">
        <Button
          isValid={accountType !== null}
          type={'button'}
          title={'Continue'}
          onClick={() => navigate('/sign-up', { state: { accountType } })}
        />

        <UrlText
          text={'Already have an account?'}
          urlText={'Login here'}
          url={'/sign-in'}
        />
      </div>
    </Layout>
  );
};

export default Welcome;
