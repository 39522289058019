/* eslint-disable @typescript-eslint/no-empty-function */
// import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';

import MerchantInputField from './Merchant-E-card-input-field';
import SectionTitle from '../../create-ecard/section-title/section-title';
import {
  FemaleAvatar,
  MaleAvatar,
  editIcon,
  merchantStar,
} from '../../../assets/icons';
import { Form, Formik } from 'formik';
import Button from '../../../components/button/button';
import { daysToMonths, formatAmount, getDateString } from '../../../utils';

/* eslint-disable react/react-in-jsx-scope */
const MerchantECardInput = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const data = state?.data;

  return (
    <>
      <Formik
        validateOnMount
        enableReinitialize
        initialValues={{
          profile_pic: data?.profile_pic || '',
          email: data?.email || '',
          phone_number: data?.phone_number
            ? data?.phone_number.length === 10
              ? `+234${data?.phone_number}`
              : data?.phone_number
            : '',
          gender: data?.gender ? (data?.gender === 1 ? 'male' : 'female') : '',
          bvn: data?.bvn || '',
          nin: data?.bvn || '',
          product_type: data?.product?.product_type || '',
          minimum_amount:
            `₦${formatAmount(data.minimum_amount)}/${data?.product?.minimum_frequency}` ||
            '',
          minimum_frequency: data?.product?.product?.minimum_frequency || '',
          minimum_duration: daysToMonths(data?.product?.minimum_duration) || '',
          residential_address: data?.residential_address || '',
          nok_first_name: data?.nok_first_name || '',
          nok_phone_number: data?.nok_phone_number || '',
          nok_email_address: data?.nok_email_address || '',
        }}
        validationSchema={Yup.object({
          email: Yup.string().required('This is a required field'),
          phone_number: Yup.number().required('This is a required field'),
          gender: Yup.string().required('This is a required field'),
          bvn: Yup.string().required('This is a required field'),
          nin: Yup.string().required('This is a required field'),
          product_type: Yup.string().required('This is a required field'),
          minimum_amount: Yup.string().required('This is a required field'),
          minimum_frequency: Yup.string().required('This is a required field'),
          minimum_duration: Yup.string().required('This is a required field'),
          residential_address: Yup.string().required(
            'This is a required field'
          ),
        })}
        onSubmit={(values, { setErrors }) => {
          const errors = {};
          if (
            Object.entries(errors).length === 0 &&
            errors.constructor === Object
          ) {
            navigate('/dashboard');
          } else {
            setErrors(errors);
          }
        }}
      >
        {({ values, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div className="flex items-center justify-center mt-[60px] md:mt-10 ">
              <img
                src={
                  values.profile_pic || data?.gender === 1
                    ? MaleAvatar
                    : FemaleAvatar
                }
                alt="avatar"
                className="w-20 h-20 mb-2.5"
              />
            </div>

            <div className="flex items-baseline gap-[5px] justify-center">
              <p className="text-[16px] mb-[.5rem] font-semibold">
                {data?.first_name} {data?.last_name}
              </p>
              <img src={merchantStar} className="h-fit" alt="star" />
            </div>
            <span className="flex  justify-center text-[14px] text-[#8F8F8F] space-x-4">
              {' '}
              <h2 className="uppercase">
                CN: {data?.contributor_identifier}
              </h2>{' '}
              <h2>|</h2>{' '}
              <h2>
                {' '}
                &#x20A6;{formatAmount(data?.product?.minimum_amount)}/
                {data?.product?.minimum_frequency}
              </h2>
            </span>

            <div>
              <SectionTitle
                title={'Personal Information'}
                className={
                  'text-grey400 text-xs border-b border-grey100 h-[30px] mt-10'
                }
              />

              <div className="py-[1rem] gap-5 flex justify-between ">
                <div className="!w-[50%]">
                  <MerchantInputField
                    label="Phone Number"
                    name="phone_number"
                    placeholder=""
                    type="phone"
                    classname=""
                    disabled
                  />
                </div>

                <div className="!w-[50%]">
                  <MerchantInputField
                    label="Gender"
                    name="gender"
                    placeholder=""
                    type="text"
                    classname=""
                    disabled
                  />
                </div>
              </div>

              <MerchantInputField
                label="Residential Address"
                name="residential_address"
                placeholder=""
                type="text"
                classname=""
                disabled
              />
            </div>

            <div className="py-[1rem] gap-5 flex justify-between ">
              <div className="!w-[50%]">
                <MerchantInputField
                  label="BVN"
                  name="bvn"
                  placeholder=""
                  type="text"
                  disabled
                />
              </div>

              <div className="!w-[50%]">
                <MerchantInputField
                  label="NIN"
                  name="nin"
                  placeholder=""
                  disabled
                />
              </div>
            </div>

            <div>
              <SectionTitle
                title={'eCard Details'}
                className={
                  'text-grey400 text-xs border-b border-grey100 h-[30px] mt-10'
                }
              />
              <div className="py-[1rem] flex gap-5 justify-between ">
                <div className="!w-[50%]">
                  <MerchantInputField
                    label="Product type"
                    name="product_type"
                    placeholder=""
                    type="text"
                    classname=""
                    disabled
                  />
                </div>

                <div className="!w-[50%]">
                  <MerchantInputField
                    label="Amount/Frequency"
                    name="minimum_amount"
                    placeholder=""
                    classname=""
                    value={`₦${formatAmount(data?.product?.minimum_amount)}/${data?.product?.minimum_frequency}`}
                    disabled
                  />
                </div>
              </div>

              {values.product_type.toLocaleLowerCase() === 'fixed' && (
                <MerchantInputField
                  label="Duration"
                  name="minimum_duration"
                  placeholder=""
                  type="text"
                  classname=""
                  disabled
                />
              )}
            </div>

            <div>
              <SectionTitle
                title={'Next of Kin'}
                className={
                  'text-grey400 text-xs border-b border-grey100 h-[30px] mt-10'
                }
              />
              <div className="py-[1rem] flex gap-5 justify-between ">
                <div className="!w-[50%]">
                  <MerchantInputField
                    label="First name"
                    name="nok_first_name"
                    placeholder=""
                    type="text"
                    classname=""
                    disabled
                  />
                </div>

                <div className="!w-[50%]">
                  <MerchantInputField
                    label="Phone Number"
                    name="nok_phone_number"
                    placeholder=""
                    type="phone"
                    classname=""
                    disabled
                  />
                </div>
              </div>
              <MerchantInputField
                label="Email Address"
                name="nok_email_address"
                placeholder=""
                type="email"
                classname=""
                disabled
              />
            </div>
            <h4 className="text-[12px] w-fit py-[.2rem] px-[.5rem] mt-[3rem] bg-grey100 rounded-lg text-grey400">
              Account created at: {getDateString(data?.created_date)}
            </h4>

            <Button
              isValid={true}
              type={'button'}
              title={'Edit Details'}
              icon={editIcon}
              className="!bg-transparent border border-blue !text-blue mt-10"
              onClick={() => navigate('/edit-e-card', { state: { data } })}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default MerchantECardInput;
