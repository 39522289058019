import React, { FC } from 'react';
import BackButton from '../back-button/back-button';
import { useLocation } from 'react-router-dom';

interface Props {
  onClick?: () => void;
  middleTitle?: string;
  rightTitle?: string;
}

const TopNavigation: FC<Props> = ({ onClick, middleTitle, rightTitle }) => {
  const { pathname } = useLocation();

  return (
    <div
      className={`fixed md:relative w-full flex items-center justify-between px-5 top-0 pt-5
      pb-5 md:pb-[30px] border-b border-grey100 bg-white z-[99] left-0 right-0 md:pt-0 md:px-0
      ${pathname === '/dashboard' && '!absolute !px-0 !pt-0'}
      `}
    >
      <BackButton onClick={onClick} />
      {middleTitle && (
        <h2 className="text-sm font-semibold leading-[18px]">{middleTitle}</h2>
      )}
      {rightTitle ? (
        <div className="flex items-center gap-1.5">
          <div className="w-1 h-1 bg-violet-400 rounded-full" />{' '}
          <h2 className="text-sm font-semibold leading-none">{rightTitle}</h2>
        </div>
      ) : (
        <div className="w-20" />
      )}
    </div>
  );
};

export default TopNavigation;
