/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Layout from '../../components/layout/layout';
import OnboardTitle from '../../components/onboard-title/onboard-title';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import InputField from '../../components/input-field/input-field';
import Button from '../../components/button/button';
import UrlText from '../../components/url-text/url-text';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { _SIGN_IN } from '../../redux/auth/authActions';
import { toastify } from '../../components/toastify/toastify';

const SignIn = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { loading } = useSelector((state: RootState) => state.auth);
  // const notify = () => toast("Wow so easy !");

  return (
    <Layout>
      <OnboardTitle
        title={'Welcome back!'}
        subText={'Login with you credentials to continue'}
      />

      <Formik
        validateOnMount
        initialValues={{
          email_address: '',
          password: '',
        }}
        validationSchema={Yup.object({
          email_address: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
          password: Yup.string()
            .matches(
              /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+,.\\/;':"-]).{8,}$/,
              'Must At Least 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
            )
            .required('Password is required'),
        })}
        onSubmit={async (values, { setErrors }) => {
          const { email_address, password } = values;
          const errors = {};
          if (
            Object.entries(errors).length === 0 &&
            errors.constructor === Object
          ) {
            try {
              const res: any = await dispatch(
                _SIGN_IN({ email_address, password, navigate })
              ).unwrap();
              if (res.status) {
                navigate('/dashboard');
                return toastify('success', res.message);
              }
            } catch (error: any) {
              return toastify(
                'error',
                error?.message === 'Rejected'
                  ? 'Please try again with a stable internet connection'
                  : error?.message
              );
            }
          } else {
            setErrors(errors);
          }
        }}
      >
        {({ isValid, handleSubmit }) => (
          <Form
            onSubmit={handleSubmit}
            className="mt-10 flex flex-col gap-[30px]"
          >
            <InputField
              type="email"
              name="email_address"
              label="Email Address*"
              placeholder="email@email.com"
            />

            <div>
              <InputField
                type="password"
                name="password"
                label="Password*"
                placeholder="Password (8+ characters)"
              />
              <div className="mt-[15px]">
                <Link
                  className="text-grey400 text-xs font-medium"
                  to={'/forgot-password'}
                >
                  Forgot password
                </Link>
              </div>
            </div>

            <div className="flex flex-col gap-[25px]">
              <Button
                isValid={isValid}
                isLoading={loading}
                type={'submit'}
                title={'Login'}
              />

              <UrlText
                text={'Don’t have an account?'}
                urlText={'Create an account'}
                url={'/account'}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Layout>
  );
};

export default SignIn;
