import api from '../../utils/api';

class AuthService {
  SIGN_IN(password: string, email_address: string) {
    return api.post('auth/login', { password, email_address });
  }

  SIGN_OUT() {
    return api.post('/auth/invalidate-token');
  }
}

export default new AuthService();
