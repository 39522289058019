/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Avatar, FemaleAvatar, MaleAvatar } from '../../assets/icons';
import { formatAmount, getDateString, getTimeString } from '../../utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

interface Props {
  item: any;
}

const Transaction: FC<Props> = ({ item }) => {
  const { pathname } = useLocation();
  const [contributorDetail, setContributorDetail] = useState<any>({});
  const { contributors } = useSelector((state: RootState) => state.e_card);

  useEffect(() => {
    const contributor = contributors.find(
      (contributor: any) =>
        item?.contributor_name.includes(contributor?.first_name) &&
        item?.contributor_name.includes(contributor?.last_name)
    );
    setContributorDetail(contributor);
  }, [contributors, item]);

  return (
    <Link
      state={{ transaction: item }}
      to="/transaction-details"
      className="flex items-center justify-between w-full"
    >
      <div className="flex items-center gap-5">
        <img
          src={
            item.avatar
              ? item.avatar
              : contributorDetail?.gender === 1
                ? MaleAvatar
                : contributorDetail?.gender === 2
                  ? FemaleAvatar
                  : Avatar
          }
          alt="avatar"
          className="rounded-full w-10 h-10"
        />
        <div>
          <h6 className="text-xs font-semibold h-[15px]">
            {item.contributor_name}
          </h6>
          <p className="mt-[6px] text-grey400 text-xs font-normal h-[15px]">
            {pathname !== '/transactions' &&
              `${getDateString(item?.created_date)} @ `}{' '}
            {getTimeString(item?.created_date)}
          </p>
        </div>
      </div>

      <p
        className={`text-xs font-semibold tracking-wide text-end ${item.transaction_type === 'savings' ? 'text-green' : 'text-red'}`}
      >
        ₦
        {item?.amount_collected
          ? formatAmount(item?.amount_collected)
          : item?.amount_settled
            ? formatAmount(item?.amount_settled)
            : formatAmount(item?.amount)}
      </p>
    </Link>
  );
};

export default Transaction;
