import React, { FC, useState } from 'react';
import DashboardItem from '../dashboard-item/dashboard-item';
import { Eye } from '../../../../assets/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { Link } from 'react-router-dom';
import { formatAmount } from '../../../../utils';

export interface AccountTypeProps {
  isMerchant: boolean;
}

const Balance: FC<AccountTypeProps> = ({ isMerchant }) => {
  const [isBalanceHidden, setIsBalanceHidden] = useState<boolean>(false);
  const toggleHideBalance = () => setIsBalanceHidden(!isBalanceHidden);
  const { merchantProfile } = useSelector((state: RootState) => state.customer);
  return (
    <>
      {isMerchant ? (
        <div className="mt-6 flex items-center justify-between">
          <DashboardItem
            title={'Total Contributions'}
            value={
              merchantProfile?.total_amount_collected
                ? formatAmount(merchantProfile?.total_amount_collected)
                : '₦0.00'
            }
          />
          <DashboardItem
            title={'No. of Contributors'}
            value={merchantProfile?.no_of_contributors || '0'}
          />

          {merchantProfile?.no_of_contributors > 0 && (
            <Link
              to="/e-card/logs"
              className="absolute bottom-6 h-6 right-[55px] py-[6px] 
          flex items-center justify-center px-2.5 rounded-[50px]
           text-white text-xs bg-[#ffffff1A] cursor-pointer"
            >
              View all
            </Link>
          )}
        </div>
      ) : (
        <div className="mt-6 flex items-start">
          <DashboardItem
            title={'Savings Balance'}
            value={isBalanceHidden ? '* * * *' : '₦0.00'}
          />{' '}
          <img
            src={Eye}
            alt="eye-icon"
            className="mt-[32px]"
            onClick={toggleHideBalance}
          />
        </div>
      )}
    </>
  );
};

export default Balance;
