/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Layout from '../../components/layout/layout';
import OnboardTitle from '../../components/onboard-title/onboard-title';
import PasscodeForm from './components/passcode-form/passcode-form';
import { account_type } from '../../utils';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { toastify } from '../../components/toastify/toastify';
import { _CREATE_PIN } from '../../redux/customer/customerActions';
import { useNavigate } from 'react-router-dom';

const CreatePin = () => {
  const navigate = useNavigate();
  const accountType: any = 'merchant';
  const isMerchant = account_type(accountType);
  const dispatch = useDispatch<AppDispatch>();

  const handleSubmit = async (data: any) => {
    try {
      delete data.confirmCode;
      const res: any = await dispatch(_CREATE_PIN(data)).unwrap();

      if (isMerchant) {
        navigate('/success', {
          state: {
            title: 'Welcome!!',
            redirectTo: '/business-verification',
            btnTitle: 'Complete your profile',
            message:
              'Complete your profile today and have access seamless savings, thrift and various investment opportunities',
            lowerText: 'Later',
            lowerTextUrl: '/dashboard',
          },
        });
      } else {
        navigate('/success', {
          state: {
            title: 'Welcome!!',
            redirectTo: '/dashboard',
            btnTitle: 'Dashboard',
            message:
              'Complete your profile today and have access seamless savings, thrift and various investment opportunities',
          },
        });
      }

      return res;
    } catch (error: any) {
      toastify('error', error.message);
    }
  };

  return (
    <>
      <Layout>
        <OnboardTitle
          title={'Setup PIN'}
          subText={'Proceed to create a transaction pin'}
        />

        <PasscodeForm isMerchant={isMerchant} handleSubmit={handleSubmit} />
      </Layout>
    </>
  );
};

export default CreatePin;
