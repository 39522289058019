/* eslint-disable @typescript-eslint/no-explicit-any */
import { ISettlement } from '../../types';
import api from '../../utils/api';

class DashboardService {
  CREATE_SETTLEMENT({
    settlement_amount,
    transaction_pin,
    mode_of_settlement,
    contributor_name,
    ecard_id,
  }: ISettlement) {
    return api.post(`/settlement/create/${ecard_id}`, {
      settlement_amount: String(settlement_amount),
      transaction_pin,
      mode_of_settlement,
      contributor_name,
    });
  }

  MARK_SAVINGS({
    selected_dates,
    transaction_pin,
    contributor_name,
    contributor_id,
    ecard_id,
  }: any) {
    return api.post(`/saving/mark/${ecard_id}/${contributor_id}`, {
      selected_dates,
      transaction_pin,
      contributor_name,
    });
  }

  ADD_BANK({ bvn, bankName, accountNumber, merchant_id }: any) {
    return api.post(`/bank/add-bank/${merchant_id}`, {
      bvn,
      bankName,
      accountNumber,
    });
  }
}

export default new DashboardService();
