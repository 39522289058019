/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import Transaction from '../../../../components/transaction/transaction';
import EmptySection from '../../../../components/empty-section/empty-section';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';

const LatestTransactions = () => {
  const [data, setData] = useState<any>([]);

  const { transactions } = useSelector((state: RootState) => state.customer);

  useEffect(() => {
    if (transactions?.all?.length > 1) {
      const sortedData = [...transactions.all].sort(
        (a: any, b: any) =>
          new Date(b.created_date).getTime() -
          new Date(a.created_date).getTime()
      );
      setData(sortedData);
    }
  }, [transactions]);

  return (
    <div className="pt-10 border-t border-grey100 ">
      <div className="flex items-center justify-between">
        <p className="text-center text-grey400 text-xs font-medium">
          Latest Transactions
        </p>
        <Link
          to="/transactions"
          className="text-center text-blue text-xs font-semibold"
        >
          See all
        </Link>
      </div>

      <div className="flex flex-col gap-[25px] mt-[25px]">
        {transactions.length < 1 || transactions?.all?.length < 1 ? (
          <EmptySection
            text="Recent transactions will show up here"
            className="my-10 md:my-[90px]"
          />
        ) : (
          data
            ?.slice(0, 4)
            ?.map((item: any, index: number) => (
              <Transaction key={index} item={item} />
            ))
        )}
      </div>
    </div>
  );
};

export default LatestTransactions;
