import React, { FC, ReactNode } from 'react';
import { Fragment, useEffect } from 'react';
import { useLocation } from 'react-router';

interface Props {
  children: ReactNode;
}

const ScrollToTop: FC<Props> = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <Fragment>{children}</Fragment>;
};

export default ScrollToTop;
