/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import OTPInput from 'react-otp-input';
import Button from '../button/button';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { _RESEND_OTP } from '../../redux/customer/customerActions';

interface Props {
  title: string;
  subText: string;
  isLoading?: boolean;
  fields: number;
  handleSubmit: any;
  type?: 'settlement' | 'savings' | 'verification' | 'update-e-card';
}

const CodeConfirmation: FC<Props> = ({
  type,
  title,
  subText,
  isLoading,
  handleSubmit,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const email = localStorage.getItem('email') || '';

  const [secondsRemaining, setSecondsRemaining] = useState(0);
  const [countdownDate, setCountdownDate] = useState(new Date());
  const [isCountdownOver, setIsCountdownOver] = useState<boolean>(false);

  const { loading } = useSelector((state: RootState) => state.customer);

  const [errorMessage] = useState<string | null>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date();
      const difference = countdownDate.getTime() - currentTime.getTime();

      if (difference <= 0) {
        clearInterval(interval);
        setSecondsRemaining(0);
        setIsCountdownOver(true);
      } else {
        setIsCountdownOver(false);
        const seconds = Math.floor(difference / 1000);
        setSecondsRemaining(seconds);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [countdownDate]);

  useEffect(() => {
    const oneMinute = 60 * 1000;
    const currentTim = new Date();
    const countdownDat = new Date(currentTim.getTime() + oneMinute);
    setCountdownDate(countdownDat);
  }, []);

  const handleCodeAction = async () => {
    if (email) {
      const res: any = await dispatch(_RESEND_OTP({ email })).unwrap();

      if (res) {
        setIsCountdownOver(false);
        const oneMinute = 60 * 1000;
        const currentTim = new Date();
        const countdownDat = new Date(currentTim.getTime() + oneMinute);
        setCountdownDate(countdownDat);
      }
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  return (
    <div>
      {' '}
      <Formik
        enableReinitialize
        validateOnMount
        key={'code'}
        initialValues={{
          code: '',
        }}
        validationSchema={Yup.object({
          code: Yup.string()
            .min(6, 'Please enter a valid otp')
            .max(6, 'Please enter a valid otp')
            .required('This is a required field'),
        })}
        onSubmit={async (values, { setErrors }) => {
          const code = values.code;
          const errors = {};
          if (
            Object.entries(errors).length === 0 &&
            errors.constructor === Object
          ) {
            await handleSubmit(code);
          } else {
            setErrors(errors);
          }
        }}
      >
        {({ handleSubmit, isValid, setFieldValue, values }) => (
          <Form onSubmit={handleSubmit} className="flex flex-col gap-[30px]">
            <div className="verify-email-content">
              <h6 className="text-black text-base font-semibold">{title}</h6>
              <p className="mt-[10px] text-grey400 text-xs font-normal leading-5 max-w-[389px]">
                {subText}
              </p>
            </div>
            <div className="flex flex-col gap-[15px]">
              <div className="bg-[#F8F9FB] rounded-lg md:px-[14px]">
                <OTPInput
                  value={values.code}
                  onChange={(e) => {
                    setFieldValue('code', e);
                  }}
                  onPaste={(event) => {
                    const data = event.clipboardData.getData('text');
                    setFieldValue('code', data);
                  }}
                  inputStyle={{
                    width: 40,
                    height: 40,
                    borderRadius: 8,
                    fontSize: 17,
                    fontFamily: 'Montserrat',
                    background: 'white',
                    border: '1px solid  #F0F2F5',
                    outline: '#4DA34E',
                  }}
                  containerStyle={{
                    padding: '20px 16px',
                    width: '100%',
                    borderRadius: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    background: '#F8F9FB',
                  }}
                  numInputs={6}
                  renderInput={(props, index) => (
                    <input
                      {...props}
                      type="tel"
                      inputMode="numeric"
                      name={`otp-input-${index}`}
                      className={`focus:!border-green focus:!bg-lightGreen md:!w-[50px] md:!h-[50px] ${
                        errorMessage !== null &&
                        '!border !border-red !bg-lightRed'
                      }`}
                    />
                  )}
                />
              </div>

              {type === 'verification' && (
                <div
                  className={`text-xs ${isCountdownOver ? 'text-blue font-medium cursor-pointer' : 'text-grey300'}`}
                  onClick={
                    isCountdownOver ? () => handleCodeAction() : () => {}
                  }
                >
                  Resend code
                  {!isCountdownOver && <span className="text-grey300"> -</span>}
                  {!isCountdownOver && (
                    <span className="font-semibold text-grey400">
                      {' '}
                      {formatTime(secondsRemaining)}
                    </span>
                  )}
                </div>
              )}
            </div>
            <div>
              <>
                {type === 'verification' ? (
                  <Button
                    isValid={isValid}
                    isLoading={loading}
                    type={'submit'}
                    title={'Continue'}
                    className="!w-full"
                  />
                ) : (
                  <div className="flex gap-4 justify-end">
                    <Button
                      isValid={true}
                      type={'button'}
                      title={'Cancel'}
                      onClick={() => navigate(-1)}
                      className="!px-5 !w-fit md:px-10 bg-lightRed !text-red"
                    />
                    <Button
                      isValid={isValid}
                      type={'submit'}
                      title={
                        type === 'update-e-card'
                          ? 'Continue'
                          : 'Confirm Payment'
                      }
                      isLoading={isLoading}
                      className="!min-w-[170px] !w-fit px-5 md:!w-[208px]"
                    />
                  </div>
                )}
              </>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CodeConfirmation;
