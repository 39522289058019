import React, { useState } from 'react';
import Layout from '../../components/layout/layout';
import TopNavigation from '../../components/top-navigation/top-navigation';

import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import SelectField from '../../components/select-field/select-field';
import InputField from '../../components/input-field/input-field';
import Button from '../../components/button/button';
import { Banks } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { _ADD_BANK } from '../../redux/dashboard/dashboardAction';
import { toastify } from '../../components/toastify/toastify';

const AddBank = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [accountName] = useState<string | null>(null);
  const { merchantProfile } = useSelector((state: RootState) => state.customer);

  return (
    <Layout>
      <TopNavigation middleTitle="ADD BANK" />

      <div className="pt-[60px] md:pt-10 flex flex-col gap-10 ">
        <Formik
          validateOnMount
          initialValues={{
            bankName: '',
            accountNumber: '',
            bvn: '',
          }}
          validationSchema={Yup.object({
            bvn: Yup.string().required('This is a required field'),
            bankName: Yup.string().required('This is a required field'),
            accountNumber: Yup.string()
              .min(10, 'Invalid account number')
              .max(10, 'Invalid account number')
              .required('This is a required field'),
          })}
          onSubmit={async (values, { setErrors }) => {
            const { bvn, bankName, accountNumber } = values;
            const errors = {};
            if (
              Object.entries(errors).length === 0 &&
              errors.constructor === Object
            ) {
              try {
                const res: any = await dispatch(
                  _ADD_BANK({
                    bvn,
                    navigate,
                    bankName,
                    accountNumber,
                    customer_id: merchantProfile?.customer_id,
                  })
                ).unwrap();
              } catch (error: any) {
                return toastify('error', error.message);
              }

              navigate('/success', {
                state: {
                  title: 'Bank added successful',
                  redirectTo: '/business-profile',
                  message:
                    'At just 30 years old, he is widely considered one of Europe’s most talented and accomplished young entrepreneurs and philosophical thinkers.',
                },
              });
            } else {
              setErrors(errors);
            }
          }}
        >
          {({ isValid, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit} className="flex flex-col gap-10">
              <SelectField
                data={Banks}
                label={'Select Bank*'}
                placeholder={'Select bank'}
                setFieldValue={(value: string) => {
                  setFieldValue('bankCode', value);
                  setFieldValue('bankName', value);
                }}
              />
              <InputField
                type="number"
                name="accountNumber"
                isAccountNumber={true}
                label="Account Number*"
                placeholder="12345678"
                accountName={accountName}
              />

              <InputField
                type="number"
                name="bvn"
                label="Bank Verification Number*"
                placeholder="12345678"
              />

              <Button isValid={isValid} type={'submit'} title={'Add Bank'} />
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};

export default AddBank;
