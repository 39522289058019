/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Welcome from './pages/welcome/welcome';
import SignIn from './pages/sign-in/sign-in';
import ForgotPassword from './pages/forgot-password/forgot-password';
import ResetPassword from './pages/reset-password/reset-password';
import SignUp from './pages/sign-up/sign-up';
import EmailConfirmation from './pages/email-confirmation/email-confirmation';
import BusinessDetails from './pages/business-details/business-details';
import Success from './pages/success/success';
import Error from './pages/error/error';
import Dashboard from './pages/dashboard/dashboard';
import Transactions from './pages/transactions/transactions';
import TransactionDetails from './pages/transaction-details/transaction-details';
import MarkSavings from './pages/mark-savings/mark-savings';
import ConfirmTransaction from './pages/confirm-transaction/confirm-transaction';
import ContributorsInformation from './pages/create-ecard/contributors-information/contributors-information';
import ECardVerification from './pages/create-ecard/e-card-verification/e-card-verification';
import ECardDetails from './pages/create-ecard/e-card-details/e-card-details';
// import Settlement from './pages/settlement/settlement';
import AccountSettings from './pages/account-settings/account-settings';
import ECard from './pages/e-card/E-card';
import MerchantECardDetails from './pages/e-card details/Merchant-E-card-details';
import EditMerchant from './pages/EditMerchant/EditMerchant';
import ChangePassword from './pages/change-password/change-password';
import BusinessProfile from './pages/business-profile/business-profile';
import AddBank from './pages/add-bank/add-bank';
import ResetPin from './pages/reset-passcode/reset-passcode';
import BusinessIdentityVerification from './pages/business-identity-verification/business-identity-verification';
import BusinessVerification from './pages/business-verification/business-verification';
import { _CURRENT_USER, _SIGN_OUT } from './redux/auth/authActions';
import { store } from './redux/store';
import axios from 'axios';
import PrivateRoutes from './components/private-routes/private-routes';
import Help from './pages/help/help';
import Referrals from './pages/referrals/referrals';
import CreatePin from './pages/create-passcode/create-passcode';
import Settlement from './pages/settlement/settlement';

const token: string | any = localStorage.getItem('token');

if (token) {
  store.dispatch(_CURRENT_USER({ token }));
} else {
  store.dispatch(_SIGN_OUT());
}

axios.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: any) => {
    if (error.response.status === 401) {
      store.dispatch(_SIGN_OUT());
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

function App() {
  useEffect(() => {
    const timer = window.setInterval(
      () => {
        window.location.reload();
        console.log('logging out after 15 mins', Date.now());
      },
      1000 * 60 * 15
    );
    return () => {
      window.clearInterval(timer);
    };
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/account" element={<Welcome />} />
          <Route path="/" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/create-password" element={<ResetPassword />} />
          <Route path="/email-confirmation" element={<EmailConfirmation />} />
          <Route path="/success" element={<Success />} />
          <Route path="/error" element={<Error />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/reset-pin" element={<ResetPin />} />
            <Route path="/create-pin" element={<CreatePin />} />
            <Route
              path="/business-verification"
              element={<BusinessVerification />}
            />
            <Route path="/business-details" element={<BusinessDetails />} />
            <Route
              path="/business-identity-verification"
              element={<BusinessIdentityVerification />}
            />
            <Route path="/mark-savings" element={<MarkSavings />} />
            <Route path="/settlement" element={<Settlement />} />
            <Route
              path="/create-e-card/contributor-information"
              element={<ContributorsInformation />}
            />
            <Route
              path="/create-e-card/verification"
              element={<ECardVerification />}
            />
            <Route path="/create-e-card/details" element={<ECardDetails />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route
              path="/transaction-details"
              element={<TransactionDetails />}
            />
            <Route
              path="/confirm-transaction"
              element={<ConfirmTransaction />}
            />
            <Route path="/account-settings" element={<AccountSettings />} />
            <Route path="/e-card/logs" element={<ECard />} />
            <Route path="/e-card/details" element={<MerchantECardDetails />} />
            <Route path="/edit-e-card" element={<EditMerchant />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/business-profile" element={<BusinessProfile />} />
            <Route path="/help" element={<Help />} />
            <Route path="/referrals" element={<Referrals />} />
            <Route path="/add-bank" element={<AddBank />} />
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>{' '}
      </BrowserRouter>
    </div>
  );
}

export default App;
