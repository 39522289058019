/* eslint-disable react/react-in-jsx-scope */

import { Dispatch, FC, SetStateAction } from 'react';
import { seachIcon } from '../../../assets/icons';

interface InputProps {
  type?: string;
  placeholder?: string;
  name?: string;
  className?: string;
  setQuery?: Dispatch<SetStateAction<string>>;
}

const EcardInput: FC<InputProps> = ({ setQuery }) => {
  return (
    <>
      <div className="relative">
        <input
          type="text"
          placeholder="Search Contributor"
          name="searchContributor"
          onChange={(e) => setQuery?.(e.target.value)}
          className="w-full py-[15px] pl-[2.3rem] my-[2rem] outline-none text-[.8rem] relative rounded-[32px] bg-[#F8F9FB]"
        />

        {/* <RiSearchLine  */}
        <img
          src={seachIcon}
          className="absolute top-[3rem]  left-[.8rem] text-[#9da4b0]"
          alt="search icon"
        />
      </div>
    </>
  );
};

export default EcardInput;
