import React, { FC } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  text: string;
  urlText: string;
  url: string;
}

const UrlText: FC<Props> = ({ text, urlText, url }) => {
  return (
    <div className="w-full text-center">
      <p className="text-grey400 text-sm font-medium">
        {text}{' '}
        <Link className="text-blue underline underline-offset-[3px]" to={url}>
          {' '}
          {urlText}
        </Link>
      </p>
    </div>
  );
};

export default UrlText;
