import React from 'react';
import Layout from '../../components/layout/layout';
import OnboardTitle from '../../components/onboard-title/onboard-title';
import RadioButton from '../business-details/components/radio-button/radio-button';
import Button from '../../components/button/button';
import { useNavigate } from 'react-router-dom';
import WindowResizeHook from '../../hooks/window-resize-hook/window-rezise-hook';

const BusinessVerification = () => {
  const navigate = useNavigate();
  const [windowWidth] = WindowResizeHook();
  return (
    <Layout>
      <div className="mt-[60px] md:mt-0" />
      <OnboardTitle
        hasNoLogo
        hasBackButton
        title={'You’re almost there'}
        subText={
          'In order to complete your profile, please provide us with the required information.'
        }
      />

      <div className="flex flex-col gap-10 mt-10">
        <RadioButton
          title={'Business Verification'}
          isActive={false}
          className="!text-black"
        />
        <RadioButton
          title={'Next of Kin'}
          isActive={false}
          className="!text-black"
        />
        <RadioButton
          title={'Identity Verification'}
          isActive={false}
          className="!text-black"
        />

        {windowWidth > 640 ? (
          <Button
            isValid={true}
            type={'button'}
            title={'Continue'}
            className="mt-[200px]"
            onClick={() => navigate('/business-details')}
          />
        ) : (
          <div className="absolute bottom-10 md:bottom-20 left-5 right-5">
            <Button
              isValid={true}
              type={'button'}
              title={'Continue'}
              // className="mt-[200px]"
              onClick={() => navigate('/business-details')}
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default BusinessVerification;
