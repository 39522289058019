import React, { useRef } from 'react';
import CustomModal from '../../components/custom-modal/custom-modal';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../components/button/button';
import { ErrorIcon } from '../../assets/icons';
import useOutsideClick from '../../hooks/use-outside-click/use-outside-click';

const Error = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const ref = useRef(null);

  useOutsideClick(() => navigate(-1), ref);

  const handleButtonClick = () => {
    if (state.redirectTo.startsWith('mailto:')) {
      window.location.href = state.redirectTo;
    } else {
      navigate(-1);
    }
  };

  return (
    <CustomModal className="md:!w-[410px] md:px-[53px] py-10">
      <div
        ref={ref}
        className="flex flex-col items-center justify-center gap-[20px]"
      >
        <img src={ErrorIcon} alt="error" className="w-[70px] md:w-[96px]" />
        <div className="text-center">
          <h2 className="text-center text-slate-900 text-lg font-semibold">
            {state.title}
          </h2>
          <p className="mt-2.5 text-center text-neutral-400 text-xs font-normal leading-5 h-fit">
            {state.message}
          </p>
        </div>
        <Button
          isValid={true}
          type={'button'}
          title={state?.btnTitle ? state?.btnTitle : 'Continue'}
          onClick={handleButtonClick}
        />
      </div>
    </CustomModal>
  );
};

export default Error;
