import React, { FC } from 'react';

interface Props {
  title: string;
  value: string;
}

const DashboardItem: FC<Props> = ({ title, value }) => {
  return (
    <div className="flex flex-col gap-2.5">
      <p className="text-grey400 text-xs font-normal leading-[14px]">{title}</p>
      <h5 className="text-white text-lg md:text-2xl font-bold leading-[30px]">
        {value}
      </h5>
    </div>
  );
};

export default DashboardItem;
