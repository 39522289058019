import { ErrorMessage, Field, useField } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { Calendar, Eye } from '../../assets/icons';
import { formatAmount } from '../../utils';

interface FieldProps {
  type?: string;
  label: string;
  name: string;
  placeholder: string;
  inputMode?: string;
  className?: string;
  isAccountNumber?: boolean;
  accountName?: string | null;
  bottomValue?: any;
  setFieldValue?: (v: string) => void;
}

const InputField: FC<FieldProps> = ({
  type,
  label,
  name,
  inputMode,
  className,
  placeholder,
  accountName,
  bottomValue,
  setFieldValue,
  isAccountNumber,
}) => {
  const [field, meta] = useField(name);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const toggleVisibility = () => setIsVisible(!isVisible);

  if (type === 'tel') {
    useEffect(() => {
      const code = '+234';
      const value = field.value;

      if (value?.charAt(0) === '0') {
        const newValue = value.slice(1);
        const phone = `${code}${newValue}`;
        setFieldValue?.(phone);
      } else {
        const phone = `${code}${value}`;
        setFieldValue?.(phone);
      }
    }, [field.value]);
  }

  return (
    <div className={`relative flex flex-col gap-[10px] ${className}`}>
      <label htmlFor="" className="text-xs font-medium leading-[14px]">
        {label}
      </label>

      <Field
        inputMode={inputMode}
        size="20"
        type={isVisible || !type ? 'text' : type}
        placeholder={placeholder}
        className={`z-10 w-full h-[46px] p-[15px] bg-gray-50 rounded-[32px] 
        text-xs font-normal leading-[14px] text-grey400 !outline-none flex !justify-left !text-left
        focus:border focus:!border-green focus:bg-lightGreen placeholder-grey300 ${
          meta.touched && meta.error && 'border border-red bg-lightRed'
        } ${type === 'tel' && 'pl-[65px]'}`}
        {...field}
      />
      {isAccountNumber && accountName !== null && (
        <div className="p-[15px] pt-9 -mt-8 -z-0 bg-lightBlue text-xs rounded-b-[24px] font-semibold text-blue">
          FirstName LastName
        </div>
      )}

      {type === 'tel' && (
        <span
          className="z-10 text-grey400 text-xs font-semibold leading-[14px] 
          border-r border-grey300 pr-[10px] absolute w-fit h-fit top-[40px] pl-[15px]"
        >
          +234
        </span>
      )}

      {type === 'date' && (
        <span className="absolute cursor-pointer z-40 top-[32px] translate-y-[36%]	right-[15px]">
          <img src={Calendar} alt="Calendar" />
        </span>
      )}

      {type === 'password' && (
        <span
          onClick={toggleVisibility}
          className="absolute z-10 cursor-pointer top-[28px] translate-y-[36%]	right-[15px]"
        >
          {isVisible ? (
            <img src={Eye} alt="eye" width={20} height={20} />
          ) : (
            <img src={Eye} alt="eye-slash" width={20} height={20} />
          )}
        </span>
      )}

      <div className="flex items-center justify-between">
        {bottomValue !== undefined && (
          <p className="text-grey400 text-xs leading-[14px] mt-1">
            Available balance: &#x20A6;
            {bottomValue > 0 ? formatAmount(bottomValue) : '0.00'}
          </p>
        )}

        <ErrorMessage
          component="p"
          name={field.name}
          className="text-red text-xs font-medium leading-[14px]"
        />
      </div>
    </div>
  );
};

export default InputField;
