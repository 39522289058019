import React, { FC } from 'react';
import { CopyIcon } from '../../assets/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

interface Props {
  title: string;
  value: string;
  canCopy?: boolean;
}

const DetailsItem: FC<Props> = ({ title, value, canCopy }) => {
  return (
    <div className="w-full h-[58px] px-4 py-2.5 bg-grey9 rounded-lg flex items-center justify-between">
      <div className="flex flex-col justify-center items-start gap-[5px]">
        <p className="text-grey400 text-[10px] font-normal">{title}</p>
        <h6 className="text-sm md:text-base font-semibold capitalize">
          {value}
        </h6>
      </div>

      {canCopy && (
        <CopyToClipboard text={value}>
          <div className="flex items-center gap-1.5">
            <p className="text-green text-[10px] font-semibold leading-3">
              COPY
            </p>
            <img src={CopyIcon} alt="copy-icon" />
          </div>
        </CopyToClipboard>
      )}
    </div>
  );
};

export default DetailsItem;
