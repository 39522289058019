/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react';
import Layout from '../../components/layout/layout';
import TopNavigation from '../../components/top-navigation/top-navigation';
import EcardInput from './component/EcardInput';
import EcardLogs from './component/EcardLogs';

const ECard = () => {
  const [query, setQuery] = useState<string>('');

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <Layout>
      <TopNavigation middleTitle="e-Cards" />
      <div className="pt-10 md:pt-0">
        <EcardInput setQuery={setQuery} />
        <EcardLogs query={query} />
      </div>
    </Layout>
  );
};

export default ECard;
