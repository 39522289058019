import { configureStore } from '@reduxjs/toolkit';
import authSlice from './auth/authSlice';
import e_cardSlice from './e-card/e_cardSlice';
import customerSlice from './customer/customerSlice';
import dashboardSlice from './dashboard/dashboardSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    customer: customerSlice,
    e_card: e_cardSlice,
    dashboard: dashboardSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
