/* eslint-disable react/react-in-jsx-scope */
import Layout from '../../components/layout/layout';
import TopNavigation from '../../components/top-navigation/top-navigation';
import MerchantECardInput from './component/Merchant-E-card-details-input';
import MerchantECardProperties from './component/Merchant-E-card-details-properties';

const MerchantECardDetails = () => {
  return (
    <>
      <Layout>
        <TopNavigation middleTitle="e-Card Details" />
        {/* <MerchantECardProperties /> */}
        <MerchantECardInput />
      </Layout>
    </>
  );
};

export default MerchantECardDetails;
