/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import CustomModal from '../../components/custom-modal/custom-modal';
import CodeConfirmation from '../../components/code-confirmation/code-confirmation';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { toastify } from '../../components/toastify/toastify';
import {
  _CREATE_SETTLEMENT,
  _MARK_SAVINGS,
} from '../../redux/dashboard/dashboardAction';
import { _UPDATE_E_CARD } from '../../redux/e-card/e_cardAction';

const ConfirmTransaction = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const type: 'settlement' | 'savings' | 'verification' | 'update-e-card' =
    state?.type;
  const { loading } = useSelector((state: RootState) => state.dashboard);
  const { loading: isLoading } = useSelector(
    (state: RootState) => state.e_card
  );

  const handleSubmit = async (pin: string) => {
    if (type === 'savings') {
      const payloadData = { ...state, transaction_pin: pin, navigate };
      delete payloadData?.type;

      try {
        await dispatch(_MARK_SAVINGS(payloadData)).unwrap();
      } catch (error: any) {
        return toastify('error', error.message);
      }
    } else if (type === 'settlement') {
      const payloadData = {
        ...state,
        transaction_pin: pin,
        navigate,
      };
      delete payloadData?.type;
      try {
        await dispatch(_CREATE_SETTLEMENT(payloadData)).unwrap();
      } catch (error: any) {
        return toastify('error', error.message);
      }
    } else if (type === 'update-e-card') {
      try {
        const res: any = await dispatch(
          _UPDATE_E_CARD({
            ...state.data,
            transaction_pin: pin,
          })
        ).unwrap();
      } catch (error: any) {
        return toastify('error', error.message);
      }
    }
  };

  return (
    <CustomModal>
      <CodeConfirmation
        fields={6}
        type={type}
        isLoading={type === 'update-e-card' ? isLoading : loading}
        handleSubmit={handleSubmit}
        title={
          type === 'update-e-card' ? 'Confirmation' : 'Confirm transaction'
        }
        subText={
          type === 'update-e-card'
            ? 'Please enter your transaction PIN to authorize this changes'
            : 'Please enter your transaction PIN to authorize this transaction'
        }
      />
    </CustomModal>
  );
};

export default ConfirmTransaction;
