/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import {
  _GET_CONTRIBUTORS,
  _GET_E_CARDS,
} from '../../../redux/e-card/e_cardAction';
import EmptySection from '../../../components/empty-section/empty-section';
import { useNavigate } from 'react-router-dom';
import { FemaleAvatar, MaleAvatar } from '../../../assets/icons';
import { formatAmount } from '../../../utils';

/* eslint-disable react/react-in-jsx-scope */
const EcardLogs = ({ query }: { query: string }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { eCards } = useSelector((state: RootState) => state.e_card);

  useEffect(() => {
    dispatch(_GET_CONTRIBUTORS());
    dispatch(_GET_E_CARDS());
  }, []);

  return (
    <>
      <div>
        <div className="flex justify-between text-[12px] text-[#8F8F8F] font-['Montserrat']">
          <div> Contributor</div>
          <div className="pr-2 w-[94px]"> Amount</div>
        </div>
        <div className=" text-[12px] font-['Montserrat'] md:h-[516px] overflow-x-auto no-scrollbar">
          {eCards?.length < 1 ? (
            <EmptySection
              text="Recent e-cards will show up here"
              className="my-20 md:my-[90px]"
            />
          ) : (
            eCards
              ?.filter(
                (obj: any) =>
                  query === '' ||
                  obj?.first_name
                    ?.toLowerCase()
                    .includes(query.toLowerCase()) ||
                  obj?.last_name?.toLowerCase().includes(query.toLowerCase())
              )
              .map(
                (eachData: any, index: number): JSX.Element => (
                  <div
                    onClick={() =>
                      navigate('/e-card/details', { state: { data: eachData } })
                    }
                    key={index}
                    className={`flex items-center justify-between py-[1.8rem] cursor-pointer  ${
                      eCards?.length - 1 === index
                        ? '!border-none !pb-0'
                        : 'border-b border-[#EFEFEF]'
                    }`}
                  >
                    <div className="flex items-center gap-[10px]">
                      {' '}
                      <div className="">
                        <img
                          src={
                            eachData?.profile_pic || eachData.gender === 1
                              ? MaleAvatar
                              : FemaleAvatar
                          }
                          alt="avater"
                          className="h-10 w-10 object-cover rounded-full"
                        />
                      </div>
                      <div>
                        <h2 className="text-[14px] font-[600]">
                          {eachData?.first_name} {eachData?.last_name}
                        </h2>
                        <h2 className="text-[10px] text-[#8F8F8F] font-[400]">
                          {eachData.ecard_name}
                        </h2>
                      </div>
                    </div>
                    <div className="">
                      <div className="text-[12px] font-[500]">
                        {' '}
                        &#x20A6;
                        {formatAmount(eachData?.product?.minimum_amount)}/
                        {eachData.product?.minimum_frequency}
                      </div>
                    </div>
                  </div>
                )
              )
          )}
        </div>
      </div>
    </>
  );
};

export default EcardLogs;

// ( _eachData : { imageUrl: string; title : string; date : string; amount : string; }, _index : number) => {

// }
