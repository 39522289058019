import React from 'react';
import Layout from '../../components/layout/layout';
import OnboardTitle from '../../components/onboard-title/onboard-title';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../components/button/button';
import SelectField from '../../components/select-field/select-field';
import FileUpload from '../create-ecard/contributors-information/file-upload/file-upload';
import { VerificationType } from '../../utils';
import { _COMPLETE_MERCHANT_PROFILE } from '../../redux/customer/customerActions';
import { toastify } from '../../components/toastify/toastify';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';

const BusinessIdentityVerification = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const { loading } = useSelector((state: RootState) => state.customer);

  delete state.terms;

  return (
    <Layout>
      <div className="mt-[60px] md:mt-0" />

      <OnboardTitle
        hasNoLogo
        hasBackButton
        title={'Identity Verification'}
        subText={
          'Please provide government issued ID (Drivers License International passport, NIN Card  or Voter’s card)'
        }
      />

      <Formik
        validateOnMount
        initialValues={{
          means_of_identification: '',
          means_of_identification_upload_url: '',
        }}
        validationSchema={Yup.object({
          //   type: Yup.string().required('This is a required field'),
        })}
        onSubmit={async (values, { setErrors }) => {
          const errors = {};
          const payloadData = { ...values, ...state, navigate };
          delete payloadData.id;
          if (
            Object.entries(errors).length === 0 &&
            errors.constructor === Object
          ) {
            try {
              const res: any = await dispatch(
                _COMPLETE_MERCHANT_PROFILE(payloadData)
              ).unwrap();
            } catch (error: any) {
              return toastify('error', error.message);
            }
          } else {
            setErrors(errors);
          }
        }}
      >
        {({ isValid, setFieldValue, handleSubmit }) => (
          <Form
            onSubmit={handleSubmit}
            className="mt-10 flex flex-col gap-[30px]"
          >
            <SelectField
              label="ID Type"
              placeholder="Select type of ID Document"
              data={VerificationType}
              setFieldValue={(value: string) =>
                setFieldValue('means_of_identification', value)
              }
            />

            <FileUpload
              type="id"
              name={'means_of_identification_upload_url'}
              label="Upload ID Document"
              placeholder="Choose file to upload"
              setFieldValue={(value) =>
                setFieldValue('means_of_identification_upload_url', value)
              }
            />

            <div className="flex flex-col gap-[20px] mt-[10px]">
              {/* <Terms
                isActive={values.terms}
                onClick={() => setFieldValue('terms', !values.terms)}
              /> */}

              <Button
                isValid={isValid}
                isLoading={loading}
                type={'submit'}
                title={'Next'}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Layout>
  );
};

export default BusinessIdentityVerification;
