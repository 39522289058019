import React, { FC } from 'react';
import { motion } from 'framer-motion';

interface Props {
  title: string;
  icon: string;
  isPurple?: boolean;
  onClick: () => void;
}

const ActionButton: FC<Props> = ({ title, icon, isPurple, onClick }) => {
  return (
    <motion.div
      onClick={onClick}
      whileTap={{ scale: 0.99 }}
      className={`h-[84px] w-[105px] flex flex-col items-center 
      justify-center gap-2.5 rounded-[10px] cursor-pointer `}
    >
      <span className="min-h-[60px] min-w-[60px] bg-grey100 rounded-full flex items-center justify-center">
        <img src={icon} alt="icon" className="w-6 h-6" />
      </span>

      <p className="text-[10px] font-medium w-full text-center">{title}</p>
    </motion.div>
  );
};

export default ActionButton;
