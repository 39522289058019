import React, { FC, ReactNode } from 'react';
import { motion } from 'framer-motion';
import LoadingState from '../loading-state/loading-state';

interface ButtonProps {
  isValid: boolean;
  type: 'button' | 'submit';
  title: string | ReactNode;
  onClick?: () => void;
  className?: string;
  isLoading?: boolean;
  icon?: string;
}

const Button: FC<ButtonProps> = ({
  type,
  icon,
  title,
  onClick,
  isValid,
  isLoading,
  className,
}) => {
  return (
    <motion.button
      onClick={onClick}
      type={type ? type : 'button'}
      disabled={!isValid}
      whileTap={{ scale: 0.99 }}
      className={`relative w-full flex items-center justify-center gap-2 h-[46px] text-white text-sm md:text-base font-semibold leading-[18px] 
      bg-blue rounded-[32px] opacity-10 px-5 cursor-pointer ${className} ${
        isValid && 'opacity-100'
      }`}
    >
      {isLoading ? (
        <LoadingState />
      ) : (
        <>
          {icon && <img src={icon} alt="" />}
          {title}
        </>
      )}
    </motion.button>
  );
};

export default Button;
