import React from 'react';
import Layout from '../../components/layout/layout';
import OnboardTitle from '../../components/onboard-title/onboard-title';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import InputField from '../../components/input-field/input-field';
import Button from '../../components/button/button';
import UrlText from '../../components/url-text/url-text';
import { useNavigate } from 'react-router-dom';
import api from '../../utils/api';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { _FORGOT_PASSWORD } from '../../redux/customer/customerActions';
import { toastify } from '../../components/toastify/toastify';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { loading } = useSelector((state: RootState) => state.customer);

  return (
    <Layout>
      <OnboardTitle
        title={'Forgot password'}
        subText={'Enter your email address to reset password'}
      />

      <Formik
        validateOnMount
        initialValues={{
          email_address: '',
        }}
        validationSchema={Yup.object({
          email_address: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        })}
        onSubmit={async ({ email_address }, { setErrors }) => {
          const errors = {};
          localStorage.setItem('recoveryEmail', email_address);

          if (
            Object.entries(errors).length === 0 &&
            errors.constructor === Object
          ) {
            try {
              const res: any = await dispatch(
                _FORGOT_PASSWORD({ email: email_address, navigate })
              ).unwrap();
              return toastify('success', res.message);
            } catch (error: any) {
              return toastify('error', error.message);
            }
          } else {
            setErrors(errors);
          }
        }}
      >
        {({ isValid, handleSubmit }) => (
          <Form
            onSubmit={handleSubmit}
            className="mt-10 flex flex-col gap-[30px]"
          >
            <InputField
              type="email"
              name="email_address"
              label="Email Address*"
              placeholder="email@email.com"
            />

            <div className="flex flex-col gap-[25px]">
              <Button
                type="submit"
                isValid={isValid}
                isLoading={loading}
                title={'Send Recovery Link'}
              />

              <UrlText
                text={'Don’t have an account?'}
                urlText={'Create an account'}
                url={'/'}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Layout>
  );
};

export default ForgotPassword;
